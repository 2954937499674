import React, { createContext, useContext } from 'react';
import ForcedOutageReducer, {
  defaultState,
  duckActions,
  IForcedOutageState,
} from 'src/ducks/forced-outage.duck';
import useReducerHook from 'src/hooks/reducer.hook';

const useReducer = (state = {}) => {
  return useReducerHook(
    ForcedOutageReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IForcedOutageContext = ReturnType<typeof useReducer>;

const ForcedOutageContext = createContext<Partial<IForcedOutageContext>>({
  state: defaultState,
}) as React.Context<IForcedOutageContext>;

type IProps = {
  state?: Partial<IForcedOutageState>;
};

const ForcedOutageProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <ForcedOutageContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </ForcedOutageContext.Provider>
  );
};

const useForcedOutageContext = () => useContext(ForcedOutageContext);

export type IUseForcedOutageContext = ReturnType<typeof useForcedOutageContext>;

export { ForcedOutageContext, ForcedOutageProvider, useForcedOutageContext };
