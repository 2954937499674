import {
  ATMAccordion,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  MOLReadonlyField,
} from 'shared-it-appmod-ui';
import React, { useState } from 'react';
import Lang from 'src/libraries/language';
import { IForcedOutagePayload } from 'src/models/forced-outage.model';
import moment from 'moment';
import styles from './forced-outage-overview.module.scss';

type IProps = {
  details: IForcedOutagePayload;
  handleEdit?: () => void;
};

const ForcedOutageOverview: React.FC<IProps> = ({ details, handleEdit }) => {
  const [active, setActive] = useState(true);

  const getOutageDate = (data: IForcedOutagePayload, isEndDate = false) => {
    if (!data) {
      return '';
    }
    const getDate = (outageDate, outageTime) => {
      const date = moment(outageDate);
      const time = moment(outageTime, 'HH:mm');
      date.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
        second: time.get('second'),
      });
      const endDate = outageDate
        ? moment(date)?.format('MM/DD/YYYY HH:mm')?.toString()
        : null;
      return endDate;
    };
    if (isEndDate) {
      return getDate(data.endDate, data.endTm);
    }
    return getDate(data.startDate, data.startTm);
  };
  const forcedOutageOverviewDetails = (
    <>
      <span className={styles.iconRight}>
        <ATMIcon circular name="cogs" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_OUTAGE_OVERVIEW}
        className={styles.header}
      />
      <ATMGrid columns={2}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_START_DATE}
              value={getOutageDate(details) ?? '-'}
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_END_DATE}
              value={getOutageDate(details, false) ?? '-'}
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_DURATION}
              value={details?.duration ? details?.duration : '-'}
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_CAUSE_CODE}
              value={details?.causeCode?.description ?? '-'}
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_SUPPLEMENTAL_CC}
              value={details?.suppCauseCode?.description ?? '-'}
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_DESCRIPTION}
              value={details?.description ? details?.description : '-'}
              inline={false}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );

  return details ? (
    forcedOutageOverviewDetails
  ) : (
    <ATMAccordion style={{ marginBottom: '1rem' }} fluid>
      {/* View of FO Overview */}
      <ATMAccordion.Title
        active={active}
        index={0}
        onClick={() => setActive((val) => !val)}
      >
        <ATMIcon name="angle down" />
        {Lang.TTL_OUTAGE_OVERVIEW}

        {handleEdit && (
          <a className={styles.edit} onClick={handleEdit} role="button">
            <ATMIcon name="edit" />
            {Lang.LBL_EDIT}
          </a>
        )}
      </ATMAccordion.Title>
    </ATMAccordion>
  );
};

export default ForcedOutageOverview;
