import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMInput,
  ATMSegment,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { AccessType } from 'src/constants';
import { hasAccess } from 'src/libraries/access.library';
import styles from './forced-outage-form.module.scss';
import {
  ForcedOutageFormSchema,
  IForcedOutage,
  IForcedOutageForm,
} from 'src/models/forced-outage.model';
import ATMStep from 'src/components/atoms/step/step.component';
import Lang from 'src/libraries/language';
// import { getUser } from 'src/libraries/amplify.library';
import { ForcedOutageRequestFormStep } from 'src/constants/forced-outage.constants';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import ForcedOutageOverviewForm from '../forced-outage-overview/forced-outage-overview-form.component';
import ForcedOutageInformationForm from '../forced-outage-information/forced-outage-information-form.component';
import FormError from 'src/components/atoms/form-error/form-error.component';
import LerRequestConfirmUnfinished from '../../ler-request/ler-request-confirm-unfinished/ler-request-confirm-unfinished.component';
import { Confirm } from 'semantic-ui-react';
import ForcedOutageRestorationForm from '../forced-outage-restoration-information/forced-outage-restoration-information-form.component';

type IContentProps = {
  header: React.ReactNode;
  formRef: React.RefObject<HTMLFormElement>;
  defaultValues?: IForcedOutage;
  draftId?: number;
  loading: boolean;
  isSubmitted?: boolean;
  handleClose?: () => void;
  handleSave?: (data: Partial<IForcedOutageForm>) => Promise<void>;
};

const stepList = [
  {
    name: ForcedOutageRequestFormStep.OUTAGE_OVERVIEW,
    access: AccessType.FORCED_OUTAGE,
  },
  {
    name: ForcedOutageRequestFormStep.OUTAGE_INFORMATION,
    access: AccessType.FORCED_OUTAGE, //AccessType.KEARNY,
  },
  {
    name: ForcedOutageRequestFormStep.RESTORATION_INFORMATION,
    access: AccessType.FORCED_OUTAGE, // AccessType.GOS,
  },
];

const scrollToTop = () => {
  const element = document.getElementById('form-error');
  if (element) {
    element.scrollIntoView({
      block: 'end',
      inline: 'nearest',
    });
  }
};

const Content: React.FC<IContentProps> = ({
  header,
  // formRef,
  // defaultValues,
  loading,
  // draftId,
  handleClose,
  handleSave,
}) => {
  const {
    control,
    trigger,
    getValues,
    formState: { isValid, isDirty, errors },
    setValue,
    reset,
  } = useATMFormContext<IForcedOutageForm>();
  const [step, setStep] = useState<ForcedOutageRequestFormStep>(
    (getValues('step') as ForcedOutageRequestFormStep) ||
      ForcedOutageRequestFormStep.OUTAGE_OVERVIEW
  );

  const accessList = stepList.filter(
    (v) => !v.access || (v.access && hasAccess(v.access))
  );

  const [completedList, setCompletedList] = useState(
    [...accessList].reduce(
      (items, item, index) => ({
        ...items,
        [item.name]: accessList.findIndex((v) => v.name === step) >= index,
      }),
      {}
    )
  );

  useEffect(() => {
    setValue('step', step, {
      shouldValidate: true,
    });
  }, [step, setValue]);

  useEffect(() => {
    setCompletedList((values) => {
      const list = {
        ...values,
        [step]: isValid,
      };

      console.log('test list of omplete', list);

      // if (!Object.values(list).every(Boolean)) {
      //   return {
      //     ...list,
      //     [LERRequestFormStep.REVIEW]: false,
      //   };
      // }

      return list;
    });
  }, [isValid, step, setCompletedList]);

  return (
    <div>
      <div className={styles.header}>
        <ATMSegment attached>
          <ATMGrid columns={2}>
            <ATMGrid.Column verticalAlign="middle">{header}</ATMGrid.Column>
            <ATMGrid.Column textAlign="right">
              <ul className={styles.right}>
                <li>
                  <div className="action-buttons">
                    {step !== ForcedOutageRequestFormStep.OUTAGE_OVERVIEW && (
                      <ATMButton
                        secondary
                        icon="chevron left"
                        content="Previous"
                        type="button"
                        onClick={() => {
                          scrollToTop();
                          setStep(
                            accessList[
                              accessList.findIndex((val) => val.name === step) -
                                1
                            ].name
                          );
                        }}
                      />
                    )}

                    {accessList[
                      accessList.findIndex((val) => val.name === step) + 1
                    ] && (
                      <ATMButton
                        secondary
                        icon="chevron right"
                        labelPosition="right"
                        content="Next"
                        className={styles.btnFont}
                        type="button"
                        onClick={() => {
                          console.log('test isValid', isValid);
                          scrollToTop();
                          if (!isValid) {
                            trigger();
                            ToastError(Lang.MSG_LER_REQUEST_FORM_STEP_ERROR);

                            scrollToTop();
                          } else {
                            setStep(
                              accessList[
                                accessList.findIndex(
                                  (val) => val.name === step
                                ) + 1
                              ].name
                            );
                          }
                        }}
                      />
                    )}

                    {handleSave && isDirty && (
                      <ATMButton
                        type="button"
                        primary
                        loading={loading}
                        disabled={loading}
                        onClick={async () => {
                          const values = { ...getValues() };

                          await handleSave(values);
                          reset(values);
                        }}
                      >
                        {Lang.LBL_SAVE}
                      </ATMButton>
                    )}

                    {step ===
                      (ForcedOutageRequestFormStep.OUTAGE_INFORMATION ||
                        ForcedOutageRequestFormStep.RESTORATION_INFORMATION) && (
                      <Confirm
                        // open={isConfirm}
                        size="small"
                        // onConfirm={handleClick}
                        // onOpen={() => setIsConfirm(true)}
                        // onCancel={() => setIsConfirm(false)}
                        trigger={
                          <ATMButton
                            type="button"
                            primary
                            disabled={loading}
                            loading={loading}
                          >
                            {Lang.LBL_SUBMIT}
                          </ATMButton>
                        }
                        loading={loading}
                        header={Lang.LBL_SUBMIT_LER}
                        content={Lang.MSG_LER_CREATE_CONFIRMATION}
                      />
                    )}

                    {/* {defaultValues && (
                      <ATMDropdown
                        pointing
                        size="small"
                        button
                        icon="ellipsis horizontal"
                        className={styles.ellipsisButton}
                      >
                        <ATMDropdown.Menu>
                          {draftId && (
                            <LerRequestDeleteDraft
                              draftId={draftId}
                              trigger={
                                <ATMDropdown.Item
                                  content={Lang.LBL_DELETE_DRAFT}
                                  icon="delete"
                                />
                              }
                              handleSuccess={handleClose}
                            />
                          )}
                        </ATMDropdown.Menu>
                      </ATMDropdown>
                    )} */}
                  </div>
                </li>

                <li>
                  <div className="action-buttons">
                    <ATMButton
                      type="button"
                      color="grey"
                      basic
                      icon="external alternate"
                    />

                    {handleClose && (
                      <LerRequestConfirmUnfinished onConfirm={handleClose} />
                    )}
                  </div>
                </li>
              </ul>
            </ATMGrid.Column>
          </ATMGrid>
        </ATMSegment>
        <ATMStep
          attached="bottom"
          className={styles.step}
          items={[
            ...accessList.map((val, index) => {
              const completed = completedList[val.name];

              return {
                title: `${index + 1}. ${
                  Lang.FORCED_OUTAGE_REQUEST_STEP[val.name]
                }`,
                icon: completed ? 'check circle' : undefined,
                active: step === val.name,
                completed,
                onClick:
                  completed ||
                  (accessList[index - 1] &&
                    completedList[accessList[index - 1].name])
                    ? () => setStep(val.name)
                    : undefined,
              };
            }),
          ]}
        />
      </div>

      <div className="admin-container">
        <div id="form-error">
          <FormError
            errors={errors}
            dictionary="FORM_FORCED_OUTAGE"
            render={(field, message, temp) => {
              const title = temp;

              return (
                <>
                  <strong>{title}: </strong>
                  {message}
                </>
              );
            }}
          />
        </div>

        <ATMField
          name="step"
          as={ATMInput}
          control={control}
          value={step}
          type="hidden"
          className="hidden"
        />
        <div
          className={
            step !== ForcedOutageRequestFormStep.OUTAGE_OVERVIEW
              ? 'hidden'
              : undefined
          }
        >
          <ForcedOutageOverviewForm />
        </div>
        <div
          className={
            step !== ForcedOutageRequestFormStep.OUTAGE_INFORMATION
              ? 'hidden'
              : undefined
          }
        >
          <ForcedOutageInformationForm
            header={undefined}
            data={getValues() as unknown as IForcedOutage}
          />
        </div>
        <div
          className={
            step !== ForcedOutageRequestFormStep.RESTORATION_INFORMATION
              ? 'hidden'
              : undefined
          }
        >
          <ForcedOutageRestorationForm
          // header={undefined}
          // data={getValues() as unknown as IForcedOutage}
          />
        </div>
      </div>
    </div>
  );
};

type IProps = {
  header: React.ReactNode;
  defaultValues?: IForcedOutage;
  loading?: boolean;
  draftId?: number;
  isSubmitted?: boolean;
  handleClose?: () => void;
  handleSave?: (data: Partial<IForcedOutageForm>) => Promise<void>;
  handleSubmit: (data: IForcedOutageForm) => void;
  isClone?: boolean;
};

const ForcedOutageForm: React.FC<IProps> = ({
  header,
  defaultValues,
  loading = false,
  draftId,
  handleClose,
  handleSave,
  handleSubmit,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <div>
      <ATMForm
        ref={formRef}
        defaultValues={defaultValues as unknown as IForcedOutageForm}
        validationSchema={ForcedOutageFormSchema}
        mode="onChange"
        onSubmit={(_, { getValues }) => {
          handleSubmit && handleSubmit(getValues());
        }}
      >
        {(props) => {
          return (
            <ATMFormProvider {...props}>
              <Content
                header={header}
                formRef={formRef}
                defaultValues={defaultValues}
                loading={loading}
                draftId={draftId}
                handleClose={handleClose}
                handleSave={handleSave}
              />
            </ATMFormProvider>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default ForcedOutageForm;
