import { ForcedOutageFacilityType } from 'src/constants/forced-outage.constants';
import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';
import { FacilityLogShape } from './real-time-log.model';
import { FileSchema } from './file.model';

export const OutageSharedInfoShape = {
  id: yup.number().required(),
  outageId: yup.string().required(),
  logId: yup.number().nullable(),
  status: yup.number().required(),
  eventYr: yup.number().required(),
  description: yup.string(),
  outageClassId: yup.number().nullable(),
  substationId: yup.string().nullable(),
  equipType: yup.string().required(),
  tlId: yup.number().nullable(),
  equipId: yup.number().required(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  duration: yup.number().nullable(),
  voltage: yup.number().required(),
  createdAt: yup.date().nullable(),
  createdBy: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const OutageSharedInfoCreateShape = {
  ...OutageSharedInfoShape,
  id: yup.number(),
  // outageId: yup.string(),
  // eventYr: yup.number(),
  equipType: yup.string(),
  // equipId: yup.number(),
  duration: yup.number(),
  // voltage: yup.number(),
};

export const OutageSharedInfoPayloadShape = {
  ...OutageSharedInfoShape,
  id: yup.number().nullable(),
  createdAt: yup.date().nullable(),
  createdBy: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const ForcedOutageListSearchSchema = yup.object({
  facilityName: yup.string().nullable(),
  outageId: yup.string().nullable(),
  facilityType: yup.string().nullable(),
  dateRange: yup.array().of(yup.date()),
  substationId: yup.string().nullable(),
  outgFacId: yup.string().nullable(),
  status: yup.array().of(yup.number()).nullable(),
});

export const ForcedOutageOverviewShape = {
  equipType: yup.string().required(), //.typeError(Lang.MSG_ERROR_REQUIRED),
  equipId: yup.number().required(), //.typeError(Lang.MSG_ERROR_REQUIRED),
  tlId: yup.number().required(),
  voltage: yup.number().required(),
  status: yup.number().nullable(),
  substationId: yup.string().nullable(),
  outageClassId: yup.number().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  endTm: yup.string().nullable(),
  description: yup.string().nullable(),
};

export const ForcedOutageOverviewSchema = yup.object(ForcedOutageOverviewShape);

export const SubFaultRelayForm = yup.object({
  substation: yup.string().required(),
  faultMi: yup.number().required(),
  relayMi: yup.number().required(),
});

export const PatrolForm = yup.object({
  patrolDt: yup.date().required(),
  comment: yup.string().required(),
});

export const GOSValidateForm = yup.object({
  loadRestoreDt: yup.date().required(),
  loadDroppped: yup.boolean().required(),
  loadDropDuration: yup.number().required(),
  substationDropped: yup.string().required(),
  initiatingRowItem: yup.boolean().nullable(),
  restorationSub: yup.array(
    yup.object({
      substationId: yup.number().required(),
    })
  ),
});

export const KearnyValidateForm = yup.object({
  strNum: yup.string(),
  mileage: yup.number(),
  lineTripped: yup.boolean(),
  faultDistance: yup.boolean(),
  piNotification: yup.boolean(),
  wireDown: yup.boolean(),
  ohug: yup.string(),
});

export const ForcedOutageOverviewForm = yup.object({
  facilityTypeId: yup.number().required(), // 1Line/2Subs
  substationId: yup
    .string()
    .nullable()
    .when('facilityTypeId', {
      is: (val) => val === ForcedOutageFacilityType.Station,
      then: (schema) => schema.required(),
    }),
  equipType: yup.string().typeError(Lang.MSG_ERROR_REQUIRED),
  equipId: yup.number().typeError(Lang.MSG_ERROR_REQUIRED),
  tlId: yup.number().typeError(Lang.MSG_ERROR_REQUIRED),
  voltage: yup.number().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  duration: yup.number().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  description: yup.string().nullable(),
});

// export const ForcedOutageFormSchemaMyla = yup.object({
//   step: yup.string(),
//   outageOverview: ForcedOutageOverviewForm.nullable()
//     .optionalContent(true, null)
//     .when('step', {
//       is: (val) => !val || val === ForcedOutageFormStep.OUTAGE_OVERVIEW,
//       then: ForcedOutageOverviewForm.optionalContent(false, null),
//     }),
//   facilityInformation: KearnyValidateForm.nullable()
//     .optionalContent(true, null)
//     .when('step', {
//       is: (val) => !val || val === ForcedOutageFormStep.OUTAGE_INFO,
//       then: KearnyValidateForm.optionalContent(false, null),
//     }),
//   subFaultRelay: yup.array().of(SubFaultRelayForm).nullable(),
//   patrol: yup.array().of(PatrolForm).nullable(),
//   comments: yup
//     .array(
//       yup.object({
//         comment: yup.string(),
//         archive: yup.boolean(),
//         updatedAt: yup.date(),
//         updateBy: yup.string(),
//       })
//     )
//     .nullable(),
//   restorationInfo: GOSValidateForm.nullable()
//     .optionalContent(true, null)
//     .when('step', {
//       is: (val) => !val || val === ForcedOutageFormStep.RESTORATION_INFO,
//       then: GOSValidateForm.optionalContent(false, null),
//     }),
// });

// export const ForcedOutageShapeMyla = {
//   outageOverview: ForcedOutageOverviewSchema.required(),
//   documents: yup.array().of(FileSchema),
//   comments: yup.array(yup.string()).nullable(),
//   subFaultRelay: yup.array().of(SubFaultRelayForm).nullable(),
//   patrol: yup.array().of(PatrolForm).nullable(),
//   // KEARNY
//   strNum: yup.string(),
//   mileage: yup.number(),
//   lineTripped: yup.boolean(),
//   faultDistance: yup.boolean(),
//   piNotification: yup.boolean(),
//   wireDown: yup.boolean(),
//   ohug: yup.string(),
//   // GOS
//   loadRestoreDt: yup.date().required(),
//   loadDroppped: yup.boolean().required(),
//   loadDropDuration: yup.number().required(),
//   substationDropped: yup.string().required(),
//   initiatingRowItem: yup.boolean().nullable(),
//   restorationSub: yup.array(
//     yup.object({
//       substationId: yup.number().required(),
//     })
//   ),
// };

// MICH
export const OutageCommentShape = {
  commentId: yup.number().nullable(),
  id: yup.number().nullable(),
  comment: yup.string().nullable(),
  archive: yup.boolean().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const OutageInfoShape = {
  outageInfoId: yup.number().nullable(),
  id: yup.number().nullable(),
  strNum: yup.string().nullable(),
  mileage: yup.number().nullable(),
  lineTripped: yup.boolean().nullable(),
  faultDistance: yup.boolean().nullable(),
  piNotification: yup.boolean().nullable(),
  wireDown: yup.boolean().nullable(),
  ohUg: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const SubFaultRelayShape = {
  faultId: yup.number().nullable(),
  id: yup.number().nullable(),
  substation: yup.string().nullable(),
  faultMi: yup.number().nullable(),
  relayMi: yup.number().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const PatrolShape = {
  patrolId: yup.number().nullable(),
  id: yup.number().nullable(),
  patrolDt: yup.date().nullable(),
  comment: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const RestorationSubShape = {
  restorationSubId: yup.number().nullable(),
  id: yup.number().nullable(),
  substationId: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const RestorationInfoShape = {
  restorationId: yup.number().nullable(),
  id: yup.number().nullable(),
  loadRestoreDt: yup.date().nullable(),
  loadDroppped: yup.boolean().nullable(),
  loadDropDuration: yup.number().nullable(),
  substationDropped: yup.string().nullable(),
  initiatingRowItem: yup.boolean().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const CauseCodeShape = {
  causeCd: yup.number().nullable(),
  description: yup.string().nullable(),
};

export const UpdatedByEmployeeShape = {
  empId: yup.string().nullable(),
  firstName: yup.string().nullable(),
  fullName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  middleName: yup.string().nullable(),
};

export const OutageCommentsSchema = yup.object({
  ...OutageCommentShape,
  updatedByEmployee: yup.object(UpdatedByEmployeeShape),
});

export const ForcedOutageInfoSchema = yup.object({
  strNum: yup.string().nullable(),
  mileage: yup.number().nullable(),
  lineTripped: yup.boolean().nullable(),
  faultDistance: yup.boolean().nullable(),
  piNotification: yup.boolean().nullable(),
  wireDown: yup.boolean().nullable(),
  ohUg: yup.string().nullable(),
  subFaultRelay: yup
    .array()
    .of(
      yup.object({
        substation: yup.string().nullable(),
        faultMi: yup.number().nullable(),
        relayMi: yup.number().nullable(),
      })
    )
    .nullable(),
  patrol: yup
    .array()
    .of(
      yup.object({
        patrolDt: yup.date().nullable(),
        comment: yup.string().nullable(),
      })
    )
    .nullable(),
});

export const ForcedOutageRestorationSchema = yup.object({
  loadRestoreDt: yup.date().nullable(),
  loadDroppped: yup.boolean().nullable(),
  loadDropDuration: yup.number().nullable(),
  substationDropped: yup.string().nullable(),
  initiatingRowItem: yup.boolean().nullable(),
  interval: yup.number().nullable(),
  hftdClass: yup.string().nullable(),
  outageClass: yup.string().nullable(),
  restorationSub: yup
    .array()
    .of(
      yup.object({
        substationId: yup.string().nullable(),
      })
    )
    .nullable(),
});

export const ForcedOutageShape = {
  id: yup.number().nullable(),
  outageId: yup.string().nullable(),
  logId: yup.number().nullable(),
  eventYr: yup.number().nullable(),
  description: yup.string().nullable(),
  equipType: yup.string().nullable(),
  tlId: yup.number().nullable(),
  equipId: yup.number().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  duration: yup.number().nullable(),
  voltage: yup.number().nullable(),
  status: yup.number().nullable(),
  facilityTypeId: yup.number().nullable(),
  documents: yup.array().of(FileSchema),
  facility: yup.object(FacilityLogShape).nullable(),
  outageComments: yup.array().of(yup.object(OutageCommentShape)).nullable(),
  outageInfo: yup.object(OutageInfoShape).nullable(),
  subFaultRelay: yup.array().of(yup.object(SubFaultRelayShape)).nullable(),
  patrol: yup.array().of(yup.object(PatrolShape)).nullable(),
  restorationSub: yup.object(RestorationSubShape).nullable(),
  restorationInfo: yup.object(RestorationInfoShape).nullable(),
  causeCode: yup.object(CauseCodeShape).nullable(),
  suppCauseCode: yup.object(CauseCodeShape).nullable(),
};

export const ForcedOutagePayloadSchema = yup.object({
  id: yup.number().nullable(),
  outageId: yup.string().nullable(),
  logId: yup.number().nullable(),
  eventYr: yup.number().nullable(),
  description: yup.string().nullable(),
  equipType: yup.string().nullable(),
  tlId: yup.number().nullable(),
  equipId: yup.number().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  duration: yup.number().nullable(),
  voltage: yup.number().nullable(),
  status: yup.number().nullable(),
  facilityTypeId: yup.number().nullable(),
  causeCode: yup.object(CauseCodeShape).nullable(),
  suppCauseCode: yup.object(CauseCodeShape).nullable(),
  facility: yup.object(FacilityLogShape).nullable(),
  outageComments: yup.array().of(OutageCommentsSchema).nullable(),
  outageInformation: yup.object({
    strNum: yup.string().nullable(),
    mileage: yup.number().nullable(),
    lineTripped: yup.boolean().nullable(),
    faultDistance: yup.boolean().nullable(),
    piNotification: yup.boolean().nullable(),
    wireDown: yup.boolean().nullable(),
    ohUg: yup.string().nullable(),
    subFaultRelay: yup
      .array()
      .of(
        yup.object({
          substation: yup.string().nullable(),
          faultMi: yup.number().nullable(),
          relayMi: yup.number().nullable(),
        })
      )
      .nullable(),
    patrol: yup
      .array()
      .of(
        yup.object({
          patrolDt: yup.date().nullable(),
          comment: yup.string().nullable(),
        })
      )
      .nullable(),
  }),
  restorationInformation: yup.object({
    loadRestoreDt: yup.date().nullable(),
    loadDroppped: yup.boolean().nullable(),
    loadDropDuration: yup.number().nullable(),
    substationDropped: yup.string().nullable(),
    initiatingRowItem: yup.boolean().nullable(),
    hftd: yup.string().nullable(),
    restorationSub: yup
      .array()
      .of(
        yup.object({
          substationId: yup.string().nullable(),
          substation: yup.object({
            substationId: yup.string().nullable(),
            name: yup.string().nullable(),
          }),
        })
      )
      .nullable(),
  }),
});

export const ForcedOutageFormSchema = yup.object({
  step: yup.string(),
  // overview
  facilityTypeId: yup.number().required(), // 1Line/2Subs
  substationId: yup
    .string()
    .nullable()
    .when('facilityTypeId', {
      is: (val) => val === ForcedOutageFacilityType.Station,
      then: (schema) => schema.required(),
    }),
  equipType: yup.string().typeError(Lang.MSG_ERROR_REQUIRED),
  equipId: yup.number().typeError(Lang.MSG_ERROR_REQUIRED),
  tlId: yup.number().typeError(Lang.MSG_ERROR_REQUIRED),
  voltage: yup.number().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  description: yup.string().nullable(),
  status: yup.number(),
  comments: yup
    .array(
      yup.object({
        comment: yup.string(),
        archive: yup.boolean(),
        updatedAt: yup.date(),
        updateBy: yup.string(),
      })
    )
    .nullable(),
  outageInformation: yup.object({
    strNum: yup.string().nullable(),
    mileage: yup.number().nullable(),
    lineTripped: yup.boolean().nullable(),
    faultDistance: yup.boolean().nullable(),
    piNotification: yup.boolean().nullable(),
    wireDown: yup.boolean().nullable(),
    ohUg: yup.string().nullable(),
    subFaultRelay: yup
      .array()
      .of(
        yup.object({
          substation: yup.string().nullable(),
          faultMi: yup.number().nullable(),
          relayMi: yup.number().nullable(),
        })
      )
      .nullable(),
    patrol: yup
      .array()
      .of(
        yup.object({
          patrolDt: yup.date().nullable(),
          comment: yup.string().nullable(),
        })
      )
      .nullable(),
  }),
  restorationInformation: yup.object({
    loadRestoreDt: yup.date().nullable(),
    loadDroppped: yup.boolean().nullable(),
    loadDropDuration: yup.number().nullable(),
    substationDropped: yup.string().nullable(),
    initiatingRowItem: yup.boolean().nullable(),
    interval: yup.number().nullable(),
    hftdClass: yup.string().nullable(),
    outageClass: yup.string().nullable(),
    restorationSub: yup
      .array()
      .of(
        yup.object({
          substationId: yup.string().nullable(),
        })
      )
      .nullable(),
  }),
});

export const OutageSharedInfoCreateSchema = yup.object(
  OutageSharedInfoCreateShape
);

export const OutageSharedInfoPayloadSchema = yup.object(
  OutageSharedInfoPayloadShape
);

export const ForcedOutageSchema = yup.object(ForcedOutageShape);

export const ForcedOutageListPayloadSchema = yup.object({
  count: yup.number().nullable(),
  rows: yup.array().of(ForcedOutageSchema.clone()),
});

export const OutageCommentSchema = yup.object(OutageCommentShape);

export type IForcedOutageListPayload = yup.Asserts<
  typeof ForcedOutageListPayloadSchema
>;

export type IForcedOutageListSearch = yup.Asserts<
  typeof ForcedOutageListSearchSchema
>;

export type IOutageSharedInfoForm = yup.Asserts<
  typeof OutageSharedInfoCreateSchema
>;

export type IOutageSharedInfoPayload = yup.Asserts<
  typeof OutageSharedInfoPayloadSchema
>;

export type IForcedOutageForm = yup.Asserts<typeof ForcedOutageFormSchema>;

export type IForcedOutage = yup.Asserts<typeof ForcedOutageSchema>;
export type IForcedOutageOverview = yup.Asserts<
  typeof ForcedOutageOverviewSchema
>;

export type ISubFaultRelayForm = yup.Asserts<typeof SubFaultRelayForm>;

export type IForcedOutagePayload = yup.Asserts<
  typeof ForcedOutagePayloadSchema
>;

export type IOutageComments = yup.Asserts<typeof OutageCommentsSchema>;
