import React from 'react';
import { ATMButton } from 'shared-it-appmod-ui';
import LocalPanel from 'src/components/organisms/local-panel/local-panel.component';
import { AccessType } from 'src/constants';
import Lang from 'src/libraries/language';
import ForcedOutageRequestAdd from '../../forced-outage/forced-outage-add/forced-outage-add.component';
import ForcedOutageList from '../../forced-outage/forced-outage-list/forced-outage-list.component';

const ForcedOutages: React.FC = () => {
  const panes = [
    {
      title: Lang.TTL_FORCED_OUTAGE_LIST,
      render: <ForcedOutageList isSearch={false} />,
      permissions: [AccessType.FORCED_OUTAGE],
    },
    {
      title: Lang.TTL_FORCED_OUTAGES_SEARCH,
      render: <ForcedOutageList isSearch />,
      actions: [
        <ForcedOutageRequestAdd
          key="add"
          trigger={
            <ATMButton
              key="add"
              disabled
              primary
              content={Lang.LBL_NEW_FORCED_OUTAGE}
            />
          }
        />,
      ],
      permissions: [AccessType.FORCED_OUTAGE],
    },
    // {
    //   title: Lang.TTL_FORCED_OUTAGE_DASHBOARD,
    //   render: <ForcedOutageList isSearch={false} />,
    //   permissions: [AccessType.FORCED_OUTAGE],
    // },
  ];
  return <LocalPanel name="tab" panes={panes} />;
};

export default ForcedOutages;
