import React, { useEffect, useState } from 'react';
import {
  ATMDatePicker,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMGrid,
  ATMInput,
  ATMSelect,
  ATMTextArea,
  formatTime,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { ForcedOutageFacilityType } from 'src/constants/forced-outage.constants';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useOutageTypeContext } from 'src/contexts/outage-type.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { substationActionTypes } from 'src/ducks/substation.duck';
import Lang from 'src/libraries/language';
import { IForcedOutageForm } from 'src/models/forced-outage.model';
import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { getSubstationStatus } from 'src/selectors/substation.selector';

import moment from 'moment';
import { format24hTime } from 'src/libraries/moment.library';
import { REGEX_24HR_TIME } from 'src/constants';

type IProps = {
  isEdit?: boolean;
};

const ForcedOutageOverviewForm: React.FC<Partial<IProps>> = () => {
  const {
    control,
    formState: { errors },
    resetField,
    getValues,
  } = useATMFormContext<IForcedOutageForm>();
  // const [minDate, setMinDate] = useState<Date | undefined>(undefined);
  const [maxDate] = useState<Date | undefined>(undefined);

  const [, setTLId] = useState<number | undefined>(getValues('tlId'));
  const [causeCd, setCauseCd] = useState<number | null | undefined>(
    getValues('causeCd')
  );
  const [suppCauseCd, setSuppCauseCd] = useState<number | null | undefined>(
    getValues('suppCauseCd')
  );

  const [startDate, setStartDate] = useState<Date | undefined | null>(
    getValues('startDate')
  );

  const [startTm, setStartTm] = useState<string | undefined | null>(
    getValues('startTm')
  );

  const [endDate, setEndDate] = useState<Date | undefined | null>(
    getValues('endDate')
  );

  const [endTm, setEndTm] = useState<string | undefined | null>(
    getValues('endTm')
  );

  const [description, setDescription] = useState<string | undefined | null>(
    getValues('description')
  );

  const [equipId, setEquipId] = useState<number | undefined>(
    getValues('equipId')
  );

  // LINE/SUBSTATION
  const [facilityTypeId, setFacilityTypeId] = useState<number | undefined>(
    () => {
      const type = getValues('equipId');
      if (type) {
        return type;
      }
      // eslint-disable-next-line no-nested-ternary
      return getValues('substationId')
        ? ForcedOutageFacilityType.Station
        : equipId
        ? ForcedOutageFacilityType.Line
        : undefined;
    }
  );

  const [equipType, setEquipType] = useState<string | undefined>(
    getValues('equipType') as string
  );
  const [substationId, setSubstationId] = useState<string | undefined>(
    getValues('substationId') as string
  );
  const [voltage, setVoltage] = useState<number | undefined>(
    getValues('voltage') as number
  );

  const { state: equipmentTypeState, actions: equipmentTypeActions } =
    useEquipmentTypeContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: facilityState, actions: facilityActions } =
    useFacilityContext();
  const { state: realtimeLogState, actions: realtimeLogActions } =
    useRealTimeLogContext();
  const { actions: outageTypeActions } = useOutageTypeContext();

  const realTimeLogStatus = getRealTimeLogStatus(
    realtimeLogState,
    realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ
  );

  const substationStatus = getSubstationStatus(
    substationState,
    substationActionTypes.SUBSTATION_LIST_CREATE_READ
  );

  const equipmentTypeStatus = getEquipmentTypeStatus(
    equipmentTypeState,
    equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_READ
  );

  const voltStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_VOLT_SUBS_READ
  );

  const equipmentStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_EQUIPMENT_READ
  );

  const causeCdOptions = [
    {
      key: 1010,
      value: 1010,
      text: '1010 - Flashover Insulator (non-wash)',
    },
    {
      key: 1011,
      value: 1011,
      text: '1011 - Flashover Insulator, to be patrolled',
    },
  ];

  // const outageTypeStatus = getOutageTypeStatus(
  //   outageTypeState,
  //   outageTypeActionTypes.OUTAGE_TYPE_ACTIVE_LIST_READ
  // );

  // useEffect(() => {
  //   const lineItem = realtimeLogState.line.find(
  //     (item) => item.outgFacId === tlId
  //   );
  //   // setValue('besInd', lineItem?.besInd ?? 'N');
  // }, [tlId]);

  useEffect(() => {
    substationActions.listSubstationCreateGET({
      limit: 0,
      page: 1,
    });

    realtimeLogActions.lineGET();

    outageTypeActions.activeListGET();
  }, [substationActions, realtimeLogActions, outageTypeActions]);

  useEffect(() => {
    if (substationId) {
      equipmentTypeActions.listGET({
        limit: 0,
        page: 1,
        filters: [{ name: 'substationId', value: substationId }],
      });
    }
  }, [substationId, equipmentTypeActions]);

  useEffect(() => {
    if (equipId && substationId) {
      facilityActions.voltSubstationGET(substationId, equipId);
    }
  }, [equipId, substationId, facilityActions]);

  useEffect(() => {
    if (equipId && substationId && voltage) {
      facilityActions.equipmentGET(equipId, voltage, undefined, substationId);
    }
  }, [equipId, substationId, voltage, facilityActions]);

  // const equipment = equipmentTypeState.list.find(
  //   (val) => val.equipId === equipId
  // );

  //   useEffect(() => {
  //     // 18365 is Hotwashes
  //     if (
  //       facilityTypeId === OutageClass.Line &&
  //       outageFacilityId !== 18365 &&
  //       !isEdit
  //     ) {
  //       // If OutageClass = Line and not Hotwashes and voltNm is 69 or 138, then switchOutMin is 30
  //       const voltNm = facilityState.lerDetails?.volt?.voltNm;
  //       if (voltNm === '138' || voltNm === '69') {
  //         resetField('jobInformation.switchOutMin', {
  //           defaultValue: '30' as unknown as number,
  //         });
  //       } else {
  //         resetField('jobInformation.switchOutMin', {
  //           defaultValue: '60' as unknown as number,
  //         });
  //       }
  //       // SwitchInMin will always be 60
  //       resetField('jobInformation.switchInMin', {
  //         defaultValue: '60' as unknown as number,
  //       });
  //     } else if (
  //       outageFacilityId === 18365 ||
  //       (facilityTypeId === OutageClass.Substation && !isEdit)
  //     ) {
  //       // Always reset if Hotwashes whether on edit mode or not. If Substation, reset only if we are not on edit mode.
  //       resetField('jobInformation.switchInMin', {
  //         defaultValue: '0' as unknown as number,
  //       });
  //       resetField('jobInformation.switchOutMin', {
  //         defaultValue: '0' as unknown as number,
  //       });
  //     }
  //   }, [facilityTypeId, outageFacilityId, facilityState, isEdit, resetField]);

  return (
    <ATMGrid columns={2} style={{ maxWidth: 1023 }}>
      <ATMGrid.Column>
        <ATMForm.Field>
          <LabelRequired>{Lang.LBL_FACILITY_TYPE}</LabelRequired>
          <ATMField
            as={ATMSelect}
            placeholder={Lang.LBL_SELECT}
            control={control}
            name="facilityTypeId"
            error={errors.facilityTypeId}
            value={facilityTypeId}
            defaultValue={facilityTypeId}
            options={Object.values(ForcedOutageFacilityType)
              .filter((val) => typeof val === 'number')
              .map((value, key) => ({
                key,
                value,
                text: Lang.FACILITY_TYPE[value],
              }))}
            onChange={
              (([_, { value }]) => {
                equipmentTypeActions.listCLEAR();
                facilityActions.voltCLEAR();
                facilityActions.equipmentCLEAR();

                setFacilityTypeId(value);
                // setSubstationId(undefined);
                setEquipType(undefined);
                setVoltage(undefined);
                setTLId(undefined);

                setImmediate(() => {
                  resetField('tlId', {
                    defaultValue: null as any,
                  });
                  resetField('equipId', {
                    defaultValue: null as unknown as number,
                  });
                  setImmediate(() => {
                    resetField('substationId', {
                      defaultValue: null as any,
                    });
                    resetField('voltage', {
                      defaultValue: null as unknown as number,
                    });
                    // resetField('facTypId', {
                    //   defaultValue: null as unknown as number,
                    // });
                  });
                });

                return value;
              }) as any
            }
            selectOnBlur={false}
          />
        </ATMForm.Field>
      </ATMGrid.Column>
      <ATMGrid.Column>
        {facilityTypeId === ForcedOutageFacilityType.Line ? (
          <ATMField
            key={`type_${facilityTypeId}`}
            name="tlId"
            label={<LabelRequired>{Lang.LBL_LINE}</LabelRequired>}
            placeholder={Lang.LBL_SELECT}
            as={ATMSelect}
            control={control}
            error={errors.tlId}
            options={realtimeLogState.line.map((value) => ({
              key: value.outgFacId,
              value: value.outgFacId,
              text: value.outgFacNm,
            }))}
            onChange={([_, { value }]) => {
              //   const lineItem = realtimeLogState.line.find(
              //     (item) => item.outgFacId === value
              //   );
              // setValue('besInd', lineItem?.besInd ?? 'N');
              setTLId(value);
              // handleDefaultValueISO(value);
              return value;
            }}
            clearable
            search
            selectOnBlur={false}
            disabled={
              (!realtimeLogState.line.length && realTimeLogStatus.fetching) ||
              !facilityTypeId
            }
            loading={
              !realtimeLogState.line.length && realTimeLogStatus.fetching
            }
          />
        ) : (
          <ATMField
            name="substationId"
            label={<LabelRequired>{Lang.LBL_SUBSTATION}</LabelRequired>}
            placeholder={Lang.LBL_SELECT}
            as={ATMSelect}
            control={control}
            error={errors.substationId}
            options={substationState.listForCreate.map((value) => ({
              key: value.substationId,
              value: value.substationId,
              text: value.name,
            }))}
            onChange={([_, { value }]) => {
              equipmentTypeActions.listCLEAR();
              facilityActions.voltCLEAR();
              facilityActions.equipmentCLEAR();

              setSubstationId(value);
              setEquipType(undefined);
              setVoltage(undefined);
              setTLId(undefined);

              setImmediate(() => {
                resetField('tlId', {
                  defaultValue: null as unknown as number,
                });
                resetField('equipId', {
                  defaultValue: null as unknown as number,
                });
                resetField('voltage', {
                  defaultValue: null as unknown as number,
                });
                resetField('equipType', {
                  defaultValue: null as unknown as string,
                });
              });
              return value;
            }}
            clearable
            search
            selectOnBlur={false}
            disabled={
              (!substationState.listForCreate.length &&
                substationStatus.fetching) ||
              !facilityTypeId
            }
            loading={
              !substationState.listForCreate.length && substationStatus.fetching
            }
          />
        )}
      </ATMGrid.Column>
      {facilityTypeId === ForcedOutageFacilityType.Station ? (
        <>
          <ATMGrid.Column>
            <ATMField
              key={`equipment_type_${substationId}_${equipmentTypeState.list.length}`}
              name="equipId"
              label={<LabelRequired>{Lang.LBL_EQUIPMENT_TYPE}</LabelRequired>}
              placeholder={Lang.LBL_SELECT}
              as={ATMSelect}
              control={control}
              error={errors.equipId}
              options={equipmentTypeState.list.map((value) => ({
                key: value.facTypId,
                value: value.facTypId,
                text: value.facTypNm,
              }))}
              onChange={([_, { value }]) => {
                facilityActions.voltCLEAR();
                facilityActions.equipmentCLEAR();

                const getEquipNm = equipmentTypeState.list.find(
                  (i) => i.facTypId === value
                );

                setEquipType(getEquipNm?.facTypNm);
                setEquipId(value);
                setVoltage(undefined);
                setTLId(undefined);

                setImmediate(() => {
                  resetField('voltage', {
                    defaultValue: null as unknown as number,
                  });
                });

                return value;
              }}
              clearable
              search
              selectOnBlur={false}
              disabled={
                equipmentTypeStatus.fetching || !facilityTypeId || !substationId
              }
              loading={equipmentTypeStatus.fetching}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <ATMField
              key={`volt_${substationId}_${equipType}`}
              name="voltage"
              label={
                <LabelRequired>{Lang.LBL_EQUIPMENT_VOLTAGE}</LabelRequired>
              }
              placeholder={Lang.LBL_SELECT}
              as={ATMSelect}
              control={control}
              error={errors.voltage}
              options={facilityState.volt.map((value) => ({
                key: value.voltId,
                value: value.voltId,
                text: value.name,
              }))}
              onChange={([_, { value }]) => {
                facilityActions.equipmentCLEAR();
                setVoltage(value);
                setTLId(undefined);
                return value;
              }}
              selectOnBlur={false}
              disabled={voltStatus.fetching || !equipType}
              loading={voltStatus.fetching}
              search
              clearable
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <ATMField
              key={`equipment_${substationId}_${equipType}_${voltage}`}
              as={ATMSelect}
              name="tlId"
              label={<LabelRequired>{Lang.LBL_EQUIPMENT}</LabelRequired>}
              placeholder={Lang.LBL_SELECT}
              control={control}
              clearable
              error={errors.tlId}
              options={facilityState.equipment?.map((val) => ({
                key: val.outgFacId,
                value: val.outgFacId,
                text: val.outgFacNm,
              }))}
              onChange={([_, { value }]) => {
                const facilityItem = facilityState.list.find(
                  (item) => item.outgFacId === value
                );
                setTLId(facilityItem?.facTypId);
                return value;
              }}
              selectOnBlur={false}
              disabled={equipmentStatus.fetching || !voltage}
              loading={equipmentStatus.fetching}
              search
            />
          </ATMGrid.Column>
        </>
      ) : null}

      <ATMGrid.Row>
        <ATMGrid.Column width={4}>
          <ATMField
            name="startDate"
            label={Lang.LBL_FORCED_OUTAGE_START_DATE_AND_TIME}
            placeholder={Lang.LBL_SELECT}
            as={ATMDatePicker}
            format="MM/DD/YYYY"
            value={startDate}
            dateTimePlaceHolder="Select"
            control={control}
            maxDate={maxDate ?? new Date()}
            onChange={([_, { value: val }]) => {
              if (!val) {
                setStartDate(undefined);
              }
              // let dateValue = moment(val, 'MM/DD/YYYY');
              const day = val?.value?.getDate();
              const month = val?.value?.getMonth();
              const year = val?.value?.getFullYear();
              setStartDate(new Date(year, month, day));

              // if (dateValue) {
              //   dateValue = createDateTime(dateValue, time).toDate();
              // }
              // callback(dateValue);
              return val;
            }}
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={4}>
          <ATMField
            name="startTm"
            as={ATMInput}
            label={<label>&nbsp;</label>}
            control={control}
            autoComplete="off"
            value={format24hTime(startTm)}
            maxLength={5}
            placeholder="hh:mm"
            clearable
            onChange={([_, { value }]) => {
              // setStartTm(value);
              // return value;
              const formattedTime = formatTime(value);

              if (formattedTime) {
                const regArray = REGEX_24HR_TIME.exec(formattedTime);

                // const day = startDate?.getDate();
                // const month = startDate?.getMonth();
                // const year = startDate?.getFullYear();

                if (regArray && regArray.length === 2) {
                  const hour = Number(formattedTime.split(':')[0]);
                  const min = Number(formattedTime.split(':')[1]);

                  if (!(hour > 23 || min > 59)) {
                    setStartTm(`${hour}:${min}`);
                    // handleDateOccurredSelect(year, month, day, hour, min);
                  } else {
                    setStartTm(null);
                  }
                } else {
                  setStartTm(null);
                }
              }
              return formattedTime;
            }}
          />
        </ATMGrid.Column>

        <ATMGrid.Column width={4}>
          <ATMField
            name="endDate"
            label={Lang.LBL_FORCED_OUTAGE_END_DATE_AND_TIME}
            // label={<LabelRequired>{Lang.LBL_END_DATE_TIME}</LabelRequired>}
            placeholder={Lang.LBL_SELECT}
            as={ATMDatePicker}
            isDateTimePicker
            format="MM/DD/YYYY"
            dateTimePlaceHolder="Select"
            control={control}
            value={endDate}
            maxDate={maxDate ?? new Date()}
            onChange={([_, { value: val }]) => {
              const dateValue = moment(val, 'MM/DD/YYYY');
              setEndDate(dateValue as unknown as Date);
              // if (dateValue) {
              //   dateValue = createDateTime(dateValue, time).toDate();
              // }
              // callback(dateValue);
              return val;
            }}
          />
        </ATMGrid.Column>

        <ATMGrid.Column width={4}>
          <ATMField
            name="endTm"
            as={ATMInput}
            label={<label>&nbsp;</label>}
            control={control}
            autoComplete="off"
            value={format24hTime(endTm)}
            maxLength={5}
            placeholder="hh:mm"
            clearable
            onChange={([_, { value }]) => {
              setEndTm(value);
              return value;
              // const timeValue =
              //   value && value.length > 3
              //     ? formatTime(restrictAlphabetsAndSpecialChars(value))
              //     : restrictAlphabetsAndSpecialChars(value);
              // // return value;
              // setEndTm(timeValue);
              // return timeValue;
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Column>
        <ATMField
          name="causeCd"
          control={control}
          as={ATMDropdown}
          selection
          value={causeCd}
          defaultValue={causeCd}
          label={Lang.LBL_FORCED_OUTAGE_CAUSE_CODE}
          options={causeCdOptions}
          onChange={([_, { value }]) => {
            setCauseCd(value);
            console.log('test value of causeCd', value);
            return value;
          }}
        />
      </ATMGrid.Column>
      <ATMGrid.Column>
        <ATMField
          name="suppCauseCd"
          as={ATMDropdown}
          control={control}
          selection
          label={Lang.LBL_FORCED_OUTAGE_SUPPLEMENTAL_CC}
          value={suppCauseCd}
          defaultValue={suppCauseCd}
          options={causeCdOptions}
          onChange={([_, { value }]) => {
            setSuppCauseCd(value);
            return value;
          }}
        />
      </ATMGrid.Column>
      <ATMGrid.Column width={16}>
        <ATMField
          name="description"
          as={ATMTextArea}
          control={control}
          value={description}
          label={Lang.LBL_DESCRIPTION}
          onChange={([_, { value }]) => {
            console.log('test description', value);
            setDescription(value);
            return value;
          }}
        />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default ForcedOutageOverviewForm;
