import React from 'react';
import { ATMButton, ATMTable } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IForcedOutageForm } from 'src/models/forced-outage.model';

type Iprops = {
  data: Partial<IForcedOutageForm>;
};

const ForcedOutageSubFaultRelay: React.FC<Iprops> = ({ data }) => {
  return (
    <>
      {/* Table */}
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell colSpan={2}>
              {Lang.LBL_FORCED_OUTAGE_SUB}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>
              {Lang.LBL_FORCED_OUTAGE_FAULT_MI}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>
              {Lang.LBL_FORCED_OUTAGE_RELAY_MI}
            </ATMTable.HeaderCell>
          </ATMTable.Row>
        </ATMTable.Header>

        <ATMTable.Body>
          {data.outageInformation?.subFaultRelay &&
          data.outageInformation?.subFaultRelay.length ? (
            <ATMTable.Row>
              <ATMTable.Cell colSpan={1} textAlign="left">
                1
              </ATMTable.Cell>
              <ATMTable.Cell colSpan={2} textAlign="left">
                DE
              </ATMTable.Cell>
              <ATMTable.Cell colSpan={2} textAlign="left">
                {data[0].faultMi}
              </ATMTable.Cell>
              <ATMTable.Cell colSpan={2} textAlign="left">
                {data[0].relayMi}
              </ATMTable.Cell>
            </ATMTable.Row>
          ) : (
            <ATMTable.Row>
              <ATMTable.Cell colSpan={5} textAlign="center">
                {Lang.NTE_NO_DATA}
              </ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
      {/* Button */}
      <div style={{ float: 'right' }}>
        <ATMButton>Add Row</ATMButton>
      </div>
    </>
  );
};

export default ForcedOutageSubFaultRelay;
