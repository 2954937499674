import React from 'react';
import { ATMButton, ATMTable } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IForcedOutageForm } from 'src/models/forced-outage.model';
type IProps = {
  data: Partial<IForcedOutageForm>;
};
const ForcedOutageRestorationSub: React.FC<IProps> = ({ data }) => {
  return (
    <>
      {/* Table */}
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell colSpan={2}>
              {Lang.LBL_SUBSTATIONS}
            </ATMTable.HeaderCell>
            {/* <ATMTable.HeaderCell>{Lang.LBL_FAULT_MI}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_RELAY_MI}</ATMTable.HeaderCell> */}
          </ATMTable.Row>
        </ATMTable.Header>

        <ATMTable.Body>
          {!data ? (
            <ATMTable.Row>
              <ATMTable.Cell colSpan={1} textAlign="left">
                1
              </ATMTable.Cell>
              <ATMTable.Cell colSpan={4} textAlign="left">
                Substation
              </ATMTable.Cell>
            </ATMTable.Row>
          ) : (
            <ATMTable.Row>
              <ATMTable.Cell colSpan={4} textAlign="center">
                {Lang.NTE_NO_DATA}
              </ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
      {/* Button */}
      <div style={{ float: 'right' }}>
        <ATMButton>Add Row</ATMButton>
      </div>
    </>
  );
};

export default ForcedOutageRestorationSub;
