import React from 'react';
import { ForcedOutageRequestFormStep } from 'src/constants/forced-outage.constants';
import { IForcedOutageForm } from 'src/models/forced-outage.model';
import ForcedOutageDocument from '../forced-outage-documents/forced-outage-documents.component';
import { ATMDivider } from 'shared-it-appmod-ui';
import ForcedOutageCommentsForm from '../forced-outage-comments/forced-outage-comments-form.component';
import ForcedOutagePanelOverview from '../forced-outage-overview/forced-outaga-panel-overview.component';

type IProps = {
  data: Partial<IForcedOutageForm>;
  handleEdit?: (step: ForcedOutageRequestFormStep) => void;
  handleDelete?: (key: string) => void;
};
const ForcedOutagePanel: React.FC<IProps> = ({
  data,
  handleEdit,
  // handleDelete,
}) => {
  return (
    <>
      {/* Overview */}
      <ForcedOutagePanelOverview
        data={data}
        handleEdit={
          handleEdit
            ? () => handleEdit(ForcedOutageRequestFormStep.OUTAGE_OVERVIEW)
            : undefined
        }
      />
      <ATMDivider />

      {/* Document */}
      <ForcedOutageDocument />
      <ATMDivider />

      {/* Comments */}
      <ForcedOutageCommentsForm data={data} />
    </>
  );
};

export default ForcedOutagePanel;
