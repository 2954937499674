export enum ForcedOutageRequestFormStep {
  OUTAGE_OVERVIEW = 'outageOverview',
  OUTAGE_INFORMATION = 'outageInformation',
  RESTORATION_INFORMATION = 'restorationInformation',
}

export enum ForcedOutagePage {
  Edit = 'forcedOutageEdit',
  View = 'forcedOutageView',
  Version = 'version',
}

export enum ForcedOutageWidth {
  LEFT = 10,
  RIGHT = 6,
}

export enum ForcedOutageFacilityType {
  Line = 1,
  Station = 2,
}

export enum ForcedOutageStatus {
  UnderReviewKearny = 1,
  UnderReviewGOS = 2,
  ReviewCompleted = 3,
}

export enum ForcedOutageStatusName {
  UnderReviewKearny = 'Under Review - Kearny',
  UnderReviewGOS = 'Under Review - GOS',
}

export enum OutageInterval {
  Sustained = 'Sustained',
  Momentary = 'Momentary',
}
