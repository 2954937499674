import React, { useEffect, useState } from 'react';
import {
  ATMButton,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMTable,
} from 'shared-it-appmod-ui';
import { ForcedOutageRequestFormStep } from 'src/constants/forced-outage.constants';
import { useFileContext } from 'src/contexts/file.context';
import Lang from 'src/libraries/language';
import styles from '../forced-outage-overview/forced-outage-overview.module.scss';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';

export type IProps = {
  tab?: string;
};

const ForcedOutageDocument: React.FC<IProps> = ({ tab }) => {
  const { actions, state } = useFileContext();
  const [files, setFiles] = useState<any[]>([]);
  const [isSharepointError] = useState<boolean>(false);

  useEffect(() => {
    setFiles(state.filename_list ?? []);
  }, [state.stash]);

  const rightPanelView = (
    <>
      <ATMGrid columns={2} style={{ maxWidth: 800 }}>
        <ATMGrid.Column>
          <span className={styles.iconRight}>
            <ATMIcon circular name="building outline" />
          </span>
          <ATMHeader
            as="h2"
            content={Lang.TTL_DOCUMENTS}
            className={styles.header}
          />
        </ATMGrid.Column>
        <ATMGrid.Column textAlign="right">
          <FileUploader
            icon="upload"
            onSuccess={() => {
              console.log('test success');
            }}
            text={Lang.LBL_UPLOAD}
            successMessage="Your file(s) has been cached, but will not be uploaded until the LER has been Created/Submitted"
            errorMessage="Failed to cache file(s)"
            displayRemoveButton={false}
            hasSharePointError={isSharepointError}
            popOverPostition="bottom right"
            popOverStyle={{ color: 'red', width: '200px' }}
          />
        </ATMGrid.Column>
      </ATMGrid>
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{Lang.LBL_FILE_NAME}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {files.length > 0 ? (
            files.map((item, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell>{item ?? '-'}</ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMButton
                    type="button"
                    icon="close"
                    onClick={() => actions.removeFileFromStash(item)}
                  />
                </ATMTable.Cell>
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row textAlign="center">
              <ATMTable.Cell colSpan={2}>
                {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
              </ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
    </>
  );

  const uploadingTable = (
    <ATMTable>
      <ATMTable.Header>
        <ATMTable.Row>
          <ATMTable.HeaderCell>{Lang.LBL_FILE_NAME}</ATMTable.HeaderCell>
          <ATMTable.HeaderCell>{Lang.LBL_UPLOADED_BY}</ATMTable.HeaderCell>
        </ATMTable.Row>
      </ATMTable.Header>
      <ATMTable.Body>
        {files?.length ? (
          files?.map((item, i) => (
            <ATMTable.Row key={i}>
              <ATMTable.Cell colSpan={4}>{item ?? '-'}</ATMTable.Cell>
              {/* <ATMTable.Cell>{item ?? '-'}</ATMTable.Cell> */}
            </ATMTable.Row>
          ))
        ) : (
          <ATMTable.Row textAlign="center">
            <ATMTable.Cell colSpan={4}>
              {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
            </ATMTable.Cell>
          </ATMTable.Row>
        )}
      </ATMTable.Body>
    </ATMTable>
  );

  const getDocumentDetails = () => {
    if (tab !== ForcedOutageRequestFormStep.OUTAGE_OVERVIEW) {
      return rightPanelView;
    }

    return uploadingTable;
  };
  return <>{getDocumentDetails()}</>;
};

export default ForcedOutageDocument;
