import React from 'react';
import { ATMGrid, ATMHeader, ATMIcon, ATMTable } from 'shared-it-appmod-ui';
import styles from './forced-outage-information-form.module.scss';
import Lang from 'src/libraries/language';
import { IForcedOutagePayload } from 'src/models/forced-outage.model';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import moment from 'moment';

type IProps = {
  data: IForcedOutagePayload;
};

const ForcedOutageInformation: React.FC<IProps> = ({ data }) => {
  const subFaultRelayDetails = (
    <>
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell />
            <ATMTable.HeaderCell content="Sub" />
            <ATMTable.HeaderCell content="Fault[mi]" />
            <ATMTable.HeaderCell content="Relay[mi]" />
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {data?.outageInformation?.subFaultRelay &&
          data?.outageInformation?.subFaultRelay?.length > 0 ? (
            data.outageInformation.subFaultRelay?.map((item, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell>{i + 1}</ATMTable.Cell>
                <ATMTable.Cell>{item.substation}</ATMTable.Cell>
                <ATMTable.Cell>{item.faultMi}</ATMTable.Cell>
                <ATMTable.Cell>{item.relayMi}</ATMTable.Cell>
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row textAlign="center">
              <ATMTable.Cell />
              <ATMTable.Cell />
              <ATMTable.Cell>{Lang.MSG_NOTIFICATION_BLOCK_EMPTY}</ATMTable.Cell>
              <ATMTable.Cell />
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
    </>
  );

  const patrolDetails = (
    <>
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell />
            <ATMTable.HeaderCell content="Patrol Date" />
            <ATMTable.HeaderCell content="Comment" />
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {data?.outageInformation?.patrol &&
          data?.outageInformation?.patrol?.length > 0 ? (
            data.outageInformation.patrol.map((item, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell>{i + 1}</ATMTable.Cell>
                <ATMTable.Cell>
                  {moment(item.patrolDt).format('MM/DD/YYYY')}
                </ATMTable.Cell>
                <ATMTable.Cell>{item.comment}</ATMTable.Cell>
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row textAlign="center">
              <ATMTable.Cell />
              <ATMTable.Cell>{Lang.MSG_NOTIFICATION_BLOCK_EMPTY}</ATMTable.Cell>
              <ATMTable.Cell />
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
    </>
  );

  const content = (
    <>
      <span>
        <ATMIcon circular name="building" />
      </span>
      <ATMHeader
        as="h2"
        className={styles.header}
        content={Lang.LBL_FORCED_OUTAGE_FACILITY_INFORMATION}
      />
      <ATMGrid columns={2}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_STRNUM}>
              {data?.outageInformation?.strNum}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_TL_MILEAGE}>
              {data?.outageInformation?.mileage}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_LINE_TRIPPED}>
              {data?.outageInformation?.lineTripped ? 'Yes' : 'No'}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_FAULT_DISTANCE}>
              {data?.outageInformation?.faultDistance ? 'Yes' : 'No'}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_PI_NOTIFICATION}>
              {data?.outageInformation?.piNotification ? 'Yes' : 'No'}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_WIRE_DOWN}>
              {data?.outageInformation?.wireDown ? 'Yes' : 'No'}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_OH_UG}>
              {data?.outageInformation?.ohUg}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column />
          <ATMGrid.Column />
        </ATMGrid.Row>
      </ATMGrid>

      {subFaultRelayDetails}
      <span>
        <ATMIcon circular name="clipboard list" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.LBL_FORCED_OUTAGE_PATROL_INFORMATION}
        className={styles.header}
      />
      {patrolDetails}
    </>
  );
  return <div>{content}</div>;
};

export default ForcedOutageInformation;
