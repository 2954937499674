/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ORGDataTable,
  IORGDataTableColumn,
  ATMButton,
  MOLNoData,
  IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import ForcedOutageListSearch from 'src/components/pages/forced-outage/forced-outage-list/forced-outage-list-search/forced-outage-list-search.component';
import { IForcedOutage } from 'src/models/forced-outage.model';
import moment from 'moment';
import ForcedOutagesStatus from 'src/components/atoms/forced-outage-status/forced-outage-status.component';
import { Link } from 'react-router-dom';
import { getUrl } from 'src/libraries/common.library';
import { ForcedOutagePage } from 'src/constants/forced-outage.constants';
import { getDuration } from './forced-outage-list.component';

type IProps = {
  data: IForcedOutage[];
  noData: string;
  loading: boolean;
  isSearch: boolean;
  filterValues?: React.MutableRefObject<Record<any, any>>;
  handleSubmit: (data: Partial<IORGDataTableQueryState>) => void;
  handleClick: () => void;
  handleDownload: (data: IForcedOutage[]) => void;
  handleSearch: (data: any) => void;
};

const ForcedOutageListView: React.FC<IProps> = ({
  data,
  noData,
  loading,
  isSearch,
  handleClick,
  handleSearch,
  handleDownload,
}) => {
  // const { currentTab, handleTabChange } = useLocationTab('id');

  const columns: IORGDataTableColumn<IForcedOutage>[] = [
    {
      index: 'outageId',
      title: Lang.LBL_FORCED_OUTAGE_ID,
      width: '10%',
      sortable: true,
      render: (_, value) => (
        <Link to={getUrl({ [ForcedOutagePage.View]: value.id })}>
          {value.outageId}
        </Link>
      ),
    },
    {
      index: 'status',
      width: '15%',
      title: Lang.LBL_FORCED_OUTAGE_REQUEST_STATUS,
      render: (_, value) => <ForcedOutagesStatus data={value} />,
    },
    {
      index: 'facility.outgFacNm',
      title: Lang.LBL_FORCED_OUTAGE_FACILITY_NAME,
      width: '15%',
      render: (_, value) => value.facility?.outgFacNm,
    },
    {
      index: 'facilityTypeId',
      width: '12%',
      sortable: true,
      title: Lang.LBL_FORCED_OUTAGE_FACILITY_TYPE,
      render: (_, value) => Lang.FACILITY_TYPE[value?.facilityTypeId ?? ''],
    },
    {
      index: 'startDate',
      width: '12%',
      sortable: true,
      title: Lang.LBL_FORCED_OUTAGE_START_DATE,
      render: (_, value) => {
        const date = moment(value?.startDate);
        const time = moment(value?.startTm, 'HH:mm');
        date.set({
          hour: time.get('hour'),
          minute: time.get('minute'),
          second: time.get('second'),
        });
        const startDate = value?.startDate
          ? moment(date)?.format('MM/DD/YYYY HH:mm')?.toString()
          : null;
        return startDate;
      },
    },
    {
      index: 'endDate',
      width: '12%',
      sortable: true,
      title: Lang.LBL_FORCED_OUTAGE_END_DATE,
      render: (_, value) => {
        const date = moment(value?.endDate);
        const time = moment(value?.endTm, 'HH:mm');
        date.set({
          hour: time.get('hour'),
          minute: time.get('minute'),
          second: time.get('second'),
        });
        const endDate = value?.startDate
          ? moment(date)?.format('MM/DD/YYYY HH:mm')?.toString()
          : null;
        return endDate;
      },
    },
    {
      index: 'duration',
      sortable: true,
      width: '8%',
      title: Lang.LBL_FORCED_OUTAGE_DURATION,
      render: (_, value) => {
        return getDuration(value);
      },
    },
    {
      index: 'causeCd',
      sortable: true,
      title: Lang.LBL_FORCED_OUTAGE_PRIMARY_CAUSE,
      width: '8%',
      render: (_, value) => value?.causeCode?.description,
    },
    {
      index: 'suppCauseCd',
      sortable: true,
      title: Lang.LBL_FORCED_OUTAGE_SECONDARY_CAUSE,
      width: '8%',
      render: (_, value) => value?.suppCauseCode?.description,
    },
  ];

  return (
    <div>
      {isSearch ? (
        <ForcedOutageListSearch
          handleSubmit={handleSearch}
          handleClick={handleClick}
          loading={loading}
        />
      ) : (
        ''
      )}
      <ORGDataTable
        columns={columns}
        data={data}
        noDataText={
          <MOLNoData
            icon="search"
            header={noData}
            subHeader="Please select or enter the search criteria and click on the search button to see the results."
          />
        }
        counter
        sortable
        loading={loading}
        total={data.length ?? 0}
        showPagination
        rowsPerPageOptions={[25, 50, 100]}
        celled={false}
        onChange={() => {
          window.scrollTo(0, 0);
        }}
      >
        {() => ({
          toolbars: [
            () => (
              <ATMButton
                secondary
                type="button"
                icon="download"
                size="tiny"
                disabled={!data || data.length === 0}
                onClick={() => handleDownload(data)}
              />
            ),
          ],
        })}
      </ORGDataTable>
    </div>
  );
};

export default ForcedOutageListView;
