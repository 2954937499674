import React from 'react';
import { ATMHeader } from 'shared-it-appmod-ui';
import styles from './forced-outage-header.module.scss';
import { IForcedOutagePayload } from 'src/models/forced-outage.model';
import ForcedOutagesStatus from 'src/components/atoms/forced-outage-status/forced-outage-status.component';

type IProps = {
  data: IForcedOutagePayload;
};

const ForcedOutageHeader: React.FC<IProps> = ({ data }) => {
  return (
    <div className={styles.wrapper}>
      <ATMHeader as="h1" content={`Outage ${data?.outageId}`} />
      <ul>
        {data?.facility && <li>{data?.facility?.outgFacNm}</li>}
        <li>{`${
          data?.facility?.volt?.voltNm
            ? `${data?.facility?.volt?.voltNm} kV`
            : 'kV'
        }`}</li>

        <li>
          <ForcedOutagesStatus data={data} />
        </li>
      </ul>
    </div>
  );
};

export default ForcedOutageHeader;
