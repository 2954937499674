/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  ATMButton,
  ATMDivider,
  ATMGrid,
  ATMLoader,
  ATMSegment,
  ATMTab,
  MOLRightPanel,
} from 'shared-it-appmod-ui';
import { AccessType } from 'src/constants';
import Lang from 'src/libraries/language';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import styles from './forced-outage-detail.module.scss';
import {
  ForcedOutageRequestFormStep,
  ForcedOutagePage,
  ForcedOutageWidth,
} from 'src/constants/forced-outage.constants';
import ForcedOutageOverview from '../forced-outage-overview/forced-outage-overview.component';
import { useForcedOutageContext } from 'src/contexts/forced-outage.context';
import Access from 'src/components/atoms/access/access.component';
import { Link } from 'react-router-dom';
import ForcedOutageHeader from 'src/components/organisms/forced-outage-header/forced-outage-header.component';
import ForcedOutageInformation from '../forced-outage-information/forced-outage-information.component';
import ForcedOutageRestorationInformation from '../forced-outage-restoration-information/forced-outage-restoration-information';
import { hasAccess } from 'src/libraries/access.library';
// import { getUrl } from 'src/libraries/common.library';
import SharePointDocumentsDetail from 'src/components/organisms/sharepoint-documents/sharepoint-documents-detail.component';
import ForcedOutageCommentsDetail from '../forced-outage-comments/forced-outage-comments.component';
import { forcedOutageListActionTypes } from 'src/ducks/forced-outage.duck';
import { getForcedOutageStatus } from 'src/selectors/forced-outage.selector';

type IContentProps = {
  id?: number;
  version?: number;
  active?: ForcedOutageRequestFormStep;
  activeTab?: ForcedOutageRequestFormStep;
  setClose: () => void;
};

const Content: React.FC<IContentProps> = ({ id, setClose, activeTab }) => {
  const {
    state,
    state: { data },
    actions,
  } = useForcedOutageContext();

  const status = getForcedOutageStatus(
    state,
    forcedOutageListActionTypes.FORCED_OUTAGE_DATA_READ
  );

  const {
    // handleChange,
    params: { active },
  } = useLocationParams([
    ForcedOutagePage.View,
    ForcedOutagePage.Edit,
    'active',
  ]);

  const [tabIndex, setTabIndex] = useState<ForcedOutageRequestFormStep>(
    active || activeTab || ForcedOutageRequestFormStep.OUTAGE_INFORMATION
  );

  useEffect(() => {
    const fetchData = async () => {
      const result = await actions.dataGET(id ?? -1);

      if (result.error) {
        setClose();
      }
      // else {
      //   // eslint-disable-next-line no-lonely-if
      //   if (result.payload) {
      //     actions.setPreviousData(result.payload);
      //   }
      // }
    };
    fetchData();
  }, [id, actions, setClose]);

  if (!data || status.fetching) {
    return <ATMLoader active />;
  }

  const panes = [
    {
      key: ForcedOutageRequestFormStep.OUTAGE_INFORMATION,
      access: AccessType.FORCED_OUTAGE,
      menuItem:
        Lang.FORCED_OUTAGE_REQUEST_STEP[
          ForcedOutageRequestFormStep.OUTAGE_INFORMATION
        ],
      render: <ForcedOutageInformation data={data} />,
    },
    {
      key: ForcedOutageRequestFormStep.RESTORATION_INFORMATION,
      access: AccessType.FORCED_OUTAGE,
      menuItem:
        Lang.FORCED_OUTAGE_REQUEST_STEP[
          ForcedOutageRequestFormStep.RESTORATION_INFORMATION
        ],
      render: <ForcedOutageRestorationInformation data={data} />,
    },
  ].filter((v) => !v.access || hasAccess(v.access));

  const header = (
    <div className={styles.header}>
      <ATMSegment attached>
        <ATMGrid columns={2}>
          <ATMGrid.Column verticalAlign="middle" width={14}>
            <ForcedOutageHeader data={data} />
          </ATMGrid.Column>
          <ATMGrid.Column textAlign="right" verticalAlign="middle" width={2}>
            <div className={styles.right}>
              <div className="action-buttons">
                <>
                  <Access type={AccessType.FORCED_OUTAGE} when={true}>
                    <ATMButton
                      as={Link}
                      type="button"
                      primary
                      content={Lang.LBL_EDIT}
                      disabled
                      // to={getUrl({
                      //   [ForcedOutagePage.Edit]: data?.id,
                      //   [ForcedOutagePage.View]: undefined,
                      //   active: tabIndex,
                      // })}
                      to=""
                      loading={false}
                    />
                  </Access>
                </>

                <ATMButton
                  color="grey"
                  basic
                  icon="external alternate"
                  onClick={() => {
                    window.open(window.location.href, '_blank')?.focus();
                  }}
                />
                <ATMButton
                  type="button"
                  color="grey"
                  basic
                  icon="close"
                  onClick={() => {
                    setClose();
                  }}
                />
              </div>
            </div>
          </ATMGrid.Column>
          <ATMGrid.Column
            verticalAlign="middle"
            width={16}
            className={styles.headerLine}
          >
            <ATMTab
              panes={panes.map(({ render, ...item }) => item)}
              activeIndex={panes.findIndex((v) => v.key === tabIndex)}
              onTabChange={(_, value) => {
                if (value.activeIndex !== undefined && value.panes) {
                  const key = value.panes[value.activeIndex]
                    .key as ForcedOutageRequestFormStep;
                  setTabIndex(key);
                }
              }}
              menu={{ secondary: true, pointing: true }}
            />
          </ATMGrid.Column>
        </ATMGrid>
      </ATMSegment>
    </div>
  );

  const content = (
    <>
      <ATMSegment className={styles.content}>
        <ATMGrid divided>
          <ATMGrid.Column
            width={ForcedOutageWidth.LEFT}
            className="panel-full-height"
          >
            <div className={styles.container}>
              {panes.map(({ key, render }) => (
                <div
                  key={key}
                  className={tabIndex !== key ? 'hidden' : undefined}
                >
                  {render}
                </div>
              ))}
            </div>
          </ATMGrid.Column>
          <ATMGrid.Column width={ForcedOutageWidth.RIGHT}>
            <ForcedOutageOverview details={data} />
            <ATMDivider />
            <SharePointDocumentsDetail id={id} viewType="view" />
            <ATMDivider />
            <ForcedOutageCommentsDetail data={data} />
          </ATMGrid.Column>
        </ATMGrid>
      </ATMSegment>
    </>
  );

  return (
    <>
      {header}
      {content}
    </>
  );
};

type IProps = {
  trigger?: React.ReactNode;
};

const ForcedOutageDetails: React.FC<IProps> = ({ trigger }) => {
  const { params, handleChange } = useLocationParams([
    ForcedOutagePage.View,
    'active',
  ]);

  const id = params[ForcedOutagePage.View];
  const { active } = params;

  return (
    <>
      <MOLRightPanel
        isOpen={id}
        onClose={() => handleChange()}
        trigger={trigger}
        className={styles.floatingPanel}
        width="calc(100vw - 200px)"
      >
        <Content id={id} setClose={() => handleChange()} active={active} />
      </MOLRightPanel>
    </>
  );
};
export default React.memo(ForcedOutageDetails);
