import React, { useEffect, useState } from 'react';
import {
  ATMButton,
  ATMField,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMInput,
  ATMSegment,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import styles from './forced-outage-comments.module.scss';
import { IForcedOutageForm } from 'src/models/forced-outage.model';
import Moment from 'src/libraries/moment.library';

type IProps = {
  data: Partial<IForcedOutageForm>;
};

const ForcedOutageCommentsForm: React.FC<IProps> = ({ data }) => {
  const {
    // control,
    // formState: { errors },
    // resetField,
    // register,
    getValues,
    // setValue,
  } = useATMFormContext<IForcedOutageForm>();
  const [comments, setComments] = useState<any[] | null | undefined>(
    getValues('comments')
  );

  const sample = [
    {
      comment:
        'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como',
      archive: false,
      updatedAt: Moment(),
      updateBy: 'John Doe',
    },
  ];
  useEffect(() => {
    if (data) setComments(!data.comments ? sample : data.comments);
  }, [data]);

  return (
    <>
      <span className={styles.iconRight}>
        <ATMIcon circular name="comment" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.LBL_COMMENTS}
        className={styles.header}
      />
      <ATMGrid columns={2} style={{ maxWidth: 1023 }}>
        <ATMGrid.Column style={{ width: '70%' }}>
          <ATMField
            name="outageOverview.comments"
            as={ATMInput}
            //   label={Lang.LBL_COMMENTS}
          />
        </ATMGrid.Column>
        <ATMGrid.Column style={{ width: '10%', marginRight: 10 }}>
          <span>
            <ATMButton>Comment</ATMButton>
          </span>
        </ATMGrid.Column>
      </ATMGrid>
      {comments ? (
        <>
          {comments &&
            comments.map((comment, i) => (
              <ATMSegment key={i}>
                <ATMGrid columns={2}>
                  <ATMGrid.Column width={8}>
                    <span style={{ fontWeight: 'bold' }}>
                      {comment.updateBy}{' '}
                    </span>
                    <span style={{ color: '#666666' }}>
                      {Moment(comment.updatedAt).format('HH:mm MM/DD/YYYY')}
                    </span>
                  </ATMGrid.Column>
                  <ATMGrid.Column>
                    <div style={{ float: 'right' }}>
                      <ATMIcon name="delete" size="small" color="grey" />
                    </div>
                  </ATMGrid.Column>
                </ATMGrid>
                <div>{comment.comment}</div>
              </ATMSegment>
            ))}
        </>
      ) : (
        <div
          style={{
            maxWidth: 500,
            maxHeight: 500,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <span>No Comment yet</span>
        </div>
        // <div
        //   style={{
        //     maxWidth: 500,
        //     maxHeight: 500,
        //     display: 'flex',
        //     justifyContent: 'center',
        //     border: 'solid .5px',
        //     margin: '10px 0',
        //   }}
        // >
        //   <span>No Comment yet</span>

        // </div>
      )}
    </>
  );
};

export default ForcedOutageCommentsForm;
