import React from 'react';
import classNames from 'classnames';
import { ATMLabel } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  IForcedOutage,
  IForcedOutagePayload,
} from 'src/models/forced-outage.model';
import { ForcedOutageStatus } from 'src/constants/forced-outage.constants';
import styles from './forced-outage-status.module.scss';

type IProps = {
  language?: Record<string, string>;
  data?: IForcedOutagePayload | IForcedOutage;
  label?: boolean;
  bold?: boolean;
};

const ForcedOutagesStatus: React.FC<IProps> = ({
  language = Lang.FORCED_OUTAGE_STATUS,
  data,
  label = true,
  bold = false,
  children,
}) => {
  if (!data) {
    return null;
  }

  let type = 'danger';

  const { status } = data;

  if (!status) {
    return null;
  }

  switch (status) {
    case ForcedOutageStatus.UnderReviewKearny: {
      type = 'kearny';
      break;
    }

    case ForcedOutageStatus.UnderReviewGOS: {
      type = 'gos';
      break;
    }
  }

  let component = (
    <span
      className={classNames(styles.status, styles[type], {
        [styles.bold]: bold,
      })}
    >
      {children ?? language[status] ?? status}
    </span>
  );

  if (label) {
    component = (
      <ATMLabel className={classNames(styles.label, styles[type])}>
        {language[status] ?? status}{' '}
      </ATMLabel>
    );
  }

  return component;
};

export default ForcedOutagesStatus;
