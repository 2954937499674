import React, { useState } from 'react';
import {
  ATMField,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMInput,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { LERRequestWidth } from 'src/constants';
import style from './forced-outage-information-form.module.scss';
import {
  IForcedOutage,
  IForcedOutageForm,
} from 'src/models/forced-outage.model';
import ForcedOutagePanel from '../foced-outage-panel/forced-outage-panel.component';
import ForcedOutageSubFaultRelay from '../forced-outage-sub-fault-relay/forced-outage-sub-fault-relay.component';
import ForcedOutagePatrol from '../forced-outage-patrol/forced-outage-patrol.component';

type IProps = {
  header: React.ReactNode;
  defaultValues?: IForcedOutage;
  data: IForcedOutage;
  loading?: boolean;
  draftId?: number;
  isSubmitted?: boolean;
  handleClose?: () => void;
  handleSave?: (data: Partial<IForcedOutageForm>) => Promise<void>;
  handleSubmit?: (data: IForcedOutageForm) => void;
  isClone?: boolean;
};

type IPropFormContent = {
  data?: Partial<IForcedOutage | IForcedOutageForm>;
};

const FormContent: React.FC<IPropFormContent> = () => {
  const { control, getValues } = useATMFormContext<IForcedOutageForm>();
  const [strNum, setStrNum] = useState<string | undefined | null>(
    getValues('outageInformation.strNum')
  );
  const [mileage, setMileage] = useState<number | undefined | null>(
    getValues('outageInformation.mileage')
  );
  return (
    <>
      {/* <div className={style.formContent}></div> */}
      <span className={style.iconRight}>
        <ATMIcon circular name="calendar" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_OUTAGE_OVERVIEW}
        className={style.header}
      />
      <ATMGrid columns={3} style={{ maxWidth: 1023 }}>
        {/* </ATMGrid.Row><ATMGrid.Row> */}
        <ATMGrid.Column>
          <ATMField
            name="facilityInformation.strNum"
            as={ATMInput}
            label={Lang.LBL_FORCED_OUTAGE_STRNUM}
            control={control}
            value={strNum}
            defaulValue={strNum}
            onChange={(_, { value }) => {
              console.log('test strNUm', value);
              setStrNum(value);
            }}
          />
          {/* <Label attached="bottom">
                  <ATMHeader as="h2" content={Lang.LBL_FACILITY_INFORMATION} />
                </Label> */}
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="facilityInformation.mileage"
            as={ATMInput}
            label={Lang.LBL_FORCED_OUTAGE_TL_MILEAGE}
            control={control}
            value={mileage}
            defaulValue={mileage}
            onChange={(_, { value }) => {
              setMileage(value);
            }}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="facilityInformation.lineTripped"
            as={ATMInput}
            label={Lang.LBL_FORCED_OUTAGE_LINE_TRIPPED}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="facilityInformation.faultDistance"
            as={ATMInput}
            label={Lang.LBL_FORCED_OUTAGE_FAULT_DISTANCE}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="facilityInformation.piNotification"
            as={ATMInput}
            label={Lang.LBL_FORCED_OUTAGE_PI_NOTIFICATION}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="facilityInformation.wireDown"
            as={ATMInput}
            label={Lang.LBL_FORCED_OUTAGE_WIRE_DOWN}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="facilityInformation.ohug"
            as={ATMInput}
            label={Lang.LBL_FORCED_OUTAGE_OH_UG}
          />
        </ATMGrid.Column>

        <ATMGrid.Column width={16}>
          <ForcedOutageSubFaultRelay
            data={getValues() as Partial<IForcedOutageForm>}
          />
        </ATMGrid.Column>
        {/* <ATMGrid.Row></ATMGrid.Row> */}
        <ATMGrid.Column width={16}>
          <ForcedOutagePatrol
            data={getValues() as Partial<IForcedOutageForm>}
          />
        </ATMGrid.Column>
      </ATMGrid>
    </>
  );
};

const ForcedOutageInformationForm: React.FC<IProps> = (
  {
    // header,
    // data,
    // defaultValues,
    // loading = false,
    // draftId,
    // handleClose,
    // handleSave,
    // handleSubmit,
  }
) => {
  const { getValues } = useATMFormContext<IForcedOutageForm>();
  // const values = getValues();
  // console.log('test values', values);
  // const formRef = useRef<HTMLFormElement>(null);
  // const user = getUser();

  return (
    <ATMGrid divided>
      <ATMGrid.Column width={LERRequestWidth.LEFT}>
        <FormContent />
      </ATMGrid.Column>
      <ATMGrid.Column width={LERRequestWidth.RIGHT}>
        {/* <div>TEST1</div> */}
        <ForcedOutagePanel data={getValues()} />
        {/* <LERRequestPanel data={getValues()} /> */}
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default ForcedOutageInformationForm;
