export default {
  TTL_APPLICATION: 'Mission Control Center',
  TTL_FORM_VALIDATION_ERROR: 'There were some errors with your form',

  TTL_TOAST_ERROR: 'Error',
  TTL_TOAST_SUCCESS: 'Success',
  TTL_TOAST_WARNING: 'Warning',
  TTL_TOAST_INFO: 'For your information',
  TTL_TOAST_NOTICE: 'NOTICE',

  TTL_CAISO_TR_IDS: 'CAISO TR IDs',
  TTL_EMS_IDS: 'EMS IDs',
  TTL_NOTIFICATION_BLOCK_SWITCHING_INFORMATION: 'Switching Information',
  TTL_SWITCHING: 'Switching',
  TTL_NOTIFICATION_BLOCK_NOTIFICATION_APPROVALS: 'Notifications/Approvals',

  TTL_CONFIRM_DELETE: 'Confirm Delete',

  // Navigation Tab
  TTL_KEYS: 'Keys',
  TTL_OUTAGE_PLANNING: 'Outage Planning',

  TTL_FORCED_OUTAGE_DB: 'Forced Outage',
  TTL_FORCED_OUTAGE_LIST: 'List',
  TTL_FORCED_OUTAGES_SEARCH: 'Search',
  TTL_FORCED_OUTAGE_DASHBOARD: 'Dashboard',

  // Equipment Type
  TTL_EQUIPMENT_TYPE: 'Equipment Type',
  TTL_EQUIPMENT_TYPE_ADD: 'New Equipment Type',
  TTL_EQUIPMENT_TYPE_EDIT: 'Edit Equipment Type',
  TTL_EQUIPMENT_TYPE_DELETE: 'Delete Equipment Type',

  // Key Requests
  TTL_KEY_REQUESTS: 'Key Requests',
  TTL_KEY_REQUESTOR_REPORT: 'Requestor Report',
  TTL_KEY_REQUESTS_EDIT: 'Pending Key Request for',
  TTL_KEY_REQUESTS_DELETE: 'Reject Key Request',
  TTL_VALID_KEY_NUMBER: 'Valid Key Number(s)',
  TTL_KEY_REQUEST_DETAILS: 'Request Details',

  // Real Time Log
  TTL_BPOR_AUDIT_LOGS: 'BPOR Audit Logs',

  // Facilities
  TTL_FACILITIES: 'Facilities',
  TTL_FACILITIES_EDIT: 'Edit: {0}',
  TTL_FACILITIES_ADD: 'New Facility',
  TTL_FACILITIES_DELETE: 'Delete Facility',
  TTL_ADD_GROUP_NOTIFICATION: 'Add Group',

  // Substations
  TTL_SUBSTATIONS: 'Substations',
  TTL_SUBSTAIONS_ADD: 'New Substation',
  TTL_SUBSTATAIONS_EDIT: 'Edit Substation',

  // Substation Customer Counts
  TTL_SUBSTATION_CUSTOMER_COUNTS: 'Substation Customer Counts',
  TTL_SUBSTATION_COUNT_EDIT: 'Edit - {0} Substation Customer Counts/Load',

  // Groups
  TTL_GROUPS: 'Groups',

  TTL_PAGE_NOT_FOUND: 'Error 404',
  TTL_PAGE_ACCESS_DENIED: 'Access Denied!',
  TTL_KEY_EMPLOYEE_REQUEST: 'Request Key for Employee',
  TTL_KEY_CONTRACTOR_REQUEST: 'Key Request for Contractors',

  TTL_USER_GROUP_ADD: 'New Group',
  TTL_USER_GROUP_EDIT: 'Group Details',
  TTL_USER_GROUP_DELETE: 'Delete Group',

  // Job Code Rule
  TTL_JOB_CODE_RULE: 'Job Code & Key Rules',

  // Key Details
  TTL_KEY_DETAILS: 'Key Details',
  TTL_KEY_HISTORY: 'Key History',
  TTL_JOB_CODE_RULE_ADD: 'Add Job Code And Key Rules',
  TTL_JOB_CODE_RULE_EDIT: '{0} - Edit Job Rule',

  // My Key Request
  TTL_MY_KEY_REQUEST: 'My Key Requests',

  // TSO/OSS Employee
  TTL_TSO_OSS_EMPLOYEE: 'TSO/OSS Employee List',
  TTL_TSO_OSS_EMPLOYEE_ADD: 'Add Employee',
  TTL_TSO_OSS_EMPLOYEE_EDIT: 'Edit TSO/OSS Employee {0}',
  TTL_TSO_OSS_EMPLOYEE_DELETE: 'Delete TSO/OSS Employee',

  // Workgroup
  TTL_WORK_GROUP: 'Manage Work Groups',
  // Create Ticket
  TTL_ADD_TICKET: 'Create Ticket',
  // Real Time Log
  TTL_REAL_TIME_LOG: 'Real Time Log',
  TTL_REAL_TIME_LOG_ENTRIES: 'Real Time Log Entries',
  TTL_NEW_ENTRY_LOG: 'New Entry Log',
  TTL_LOG_ENTRY: 'Log Entry',
  TTL_EDIT_ENTRY_LOG: 'Edit Real Time Log',
  TTL_BPOR_REPORT: 'BPOR Report',

  // LER
  TTL_NEW_LER: 'New LERs',
  TTL_NEW_LER_REQUEST: 'New LER Request',
  TTL_DRAFT: 'Draft',

  // Step 4 LER Request
  TTL_CREW_INFO: 'Crew Information',
  TTL_REQUESTOR_INFO: 'Requestor Information',
  TTL_GF_CA_INFO: 'GF/CA Information',
  TTL_JOB_INFO: 'Job Information',
  TTL_JOB_DETAILS: 'Job Details',
  TTL_SWITCH_IN_OUT_INFORMATION: 'Switch In/Out Information',

  // Step 5 LER Creation
  TTL_AUTHORIZATION_INFORMATION: 'Authorization Information',
  TTL_DAILY_OUTAGE_REPORTS: 'Daily Outage Reports',

  TTL_OUTAGE: 'Outage {0}',
  TTL_FACILITY_INFORMATION: 'Facility Information',

  // Authorized Members List
  TTL_AUTHORIZED_MEMBERS: 'Authorized Members',
  TTL_CONTRACTORS: 'Contractors',
  TTL_AUTH_SETTINGS: 'Application Settings',

  // Roles Settings
  TTL_EDIT_SETTINGS: 'Edit Settings',
  TTL_ROLES_LIST: 'Roles List',
  TTL_GENERAL_SETTINGS: 'General Settings',
  TTL_AUTH_LIST: 'Auth List',
  TTL_ALERT_SETTINGS: 'Alert Settings',
  TTL_MCC_SHAREPOINT: 'MCC Sharepoint',
  MSG_SYSTEM_CHANGE_CONFIRM_PARTIALLY_COMPLETE:
    'Are you sure you want to set this LER ({0}) to partially complete?',
  MSG_SYSTEM_CHANGE_CANCEL_PARTIALLY_COMPLETE:
    "Are you sure you want to clear 'Partially Complete' for this LER ({0})?",

  // Firms
  TTL_FIRMS: 'Firms',

  // Sick List
  TTL_SICK_LIST: "Today's Sick List ({0})",

  // Real-time Log
  TTL_REAL_TIME_LOG_IN_PROGRESS: 'In Progress Logs ({0})',

  // OC LER Request Cost
  TTL_OC_LER_SYS_CHANGE: 'System Change Information',
  TTL_OC_LER_EMAIL: 'Email Distribution List',
  TTL_OC_LER_SELECT_ALL: 'Select All',
  TTL_COSTS: 'Costs',

  // OC LER Request ISO
  TTL_ISO: 'ISO',
  TTL_OMS_INFORMATION: 'OMS Information',
  TTL_EQUIPMENT_RATING_CHANGE: 'Equipment Rating Change',
  TTL_SWITCHING_LER: 'Switching',
  TTL_OMS_TR_ID: 'OMS TR ID(s)',
  TTL_OMS_REQUEST: 'OMS Request',

  TTL_SELECTED_DATES: 'Selected Dates ({0})',

  // Trainers List
  TTL_TRAINERS: 'Trainers',

  // Trouble Jobs
  TTL_TROUBLE_JOBS: 'Trouble Jobs',
  TTL_ETS_DISPATCH: 'ETS Dispatch',
  TTL_TROUBLE_JOB_EDIT: 'Edit Trouble Job',
  TTL_TROUBLE_JOB_ADD: 'New Trouble Job',

  // Expiring Trainigs
  TTL_EXPIRING_TRAININGS: 'Expiring Trainings',

  //  Outage
  TTL_SCHEDULE_OUTAGE_DATES: 'Schedule Outage Dates',
  TTL_OUTAGE_CALENDAR: 'Outage Calendar',
  TTL_OUTAGES: 'Outages',
  TTL_OUTAGES_DATES: 'Outages Dates',
  TTL_OUTAGE_DATES: 'Outage Dates',
  TTL_CREW_TIME: 'Crew Time',
  TTL_CANCELLED_REPORT: 'Cancelled Report',
  TTL_OC_PENDING_ACTION_REPORT: 'OC Pending Actions Report',
  TTL_EDIT_OUTAGE_REPORT_NOTE: 'Edit Outage Report Note - LER {0}',
  TTL_DELETE_OUTAGE_REPORT_NOTE: 'Delete Outage Report Note - LER {0}',
  TTL_OUTAGE_PLANNING_SETTINGS: 'General Settings',

  TTL_LER_TITLE: 'LER {0}',
  TTL_ASSIGN_LER: 'Assign LER',

  // Documents
  TTL_DOCUMENTS: 'Documents',

  // Approval Status
  TTL_APPROVAL_STATUS: 'Approval Status',

  TTL_SUBSTATION_ENTRY_LOG: 'Substation Entry Log',
  // Rejected Reports
  TTL_REJECTED_REPORT: 'Rejected Report',

  TTL_LER_REQUEST_APPROVE: 'Approve LER Request',
  TTL_LER_REQUEST_STUDY: 'Study LER Request',
  TTL_LER_REQUEST_SUBMIT_APPROVE: 'Submit and Approve',

  TTL_LER_REQUEST_SUBMIT_CAISO: 'Submit to CAISO',
  TTL_LER_REQUEST_REJECT_CAISO: 'Submit Cancellation to CAISO',
  TTL_LER_REQUEST_APPROVED: 'LER Approved!',
  TTL_LER_REQUEST_REJECTED: 'LER Rejected!',
  TTL_CHANGE_REQUEST_PENDING: 'Change Request Pending',
  TTL_CHANGE_REQUEST_REVIEW: 'Change Request Review',
  TTL_MAX_VERSION_REACHED: 'Maximum Revision Limit Reached',

  // Tna Report
  TTL_REPORTS: 'Reports',
  TTL_TNA_REPORT: 'TNA Report',

  // OMS Audit
  TTL_OMS_AUDIT: 'OMS Audit Report',

  // Daily Reports
  TTL_DAILY_REPORTS: 'Daily Reports',

  // Version Control
  TTL_LER_REQUEST_VERSION: 'Version Control',

  // Suspension History
  TTL_SUSPENSION_HISTORY: 'Suspension History',

  // Trainin History
  TTL_TRAINING_HISTORY: 'Training History',

  TTL_OUTAGE_REQUEST_DELETE: 'Delete LER Request',
  TTL_OUTAGE_REQUEST_SCHEDULE: 'Schedule LER Request',
  TTL_OUTAGE_REQUEST_APPROVE: 'Approve LER Request',
  TTL_OUTAGE_REQUEST_CHANGE_APPROVE: 'Approve Change Request',
  TTL_OUTAGE_REQUEST_REJECT: 'Reject LER Request',
  TTL_OUTAGE_REQUEST_CHANGE_REJECT: 'Reject Change Request',
  TTL_SYSTEM_CHANGE_COMPLETION_SET: 'Set System Change Completion',
  TTL_SYSTEM_CHANGE_COMPLETION_CLEAR: 'Clear System Change Completion',
  TTL_SYSTEM_CHANGE_PARTIAL_COMPLETION_SET:
    'Set System Change to Partially Complete',
  TTL_SYSTEM_CHANGE_PARTIAL_COMPLETION_CLEAR:
    'Clear System Change Partially Complete',

  TTL_LER_REQUEST_CANCEL: 'Cancel LER Request',
  TTL_LER_REQUEST_PRINT: 'Print LER Request',

  TTL_RELATED_LERS: 'Related LER(s)',

  TTL_OTHER_DETAILS: 'Other Details',

  TTL_SYSTEM_CHANGE: 'System Change Status Report',

  TTL_SETTINGS: 'Settings',

  TTL_CATEGORIES: 'List Short Description Categories',
  TTL_CATEGORY_EDIT: 'Edit Category',
  TTL_CATEGORY_DELETE: 'Delete Category',
  TTL_DESCRIPTION_DELETE: 'Delete Description',

  TTL_TROUBLE_TICKET_AUDIT_REPORT: 'Trouble Ticket Audit Report',
  TTL_NEW_FORCED_OUTAGE: 'New Forced Outage',
  TTL_OUTAGE_OVERVIEW: 'Outage Overview',
  TTL_FORCED_OUTAGE_INFORMATION: 'Outage Information',
  TTL_FORCED_OUTAGE_RESTORATION: 'Restoration Information',
  TTL_FORCED_OUTAGE_COMMENTS: 'Comments',
  TTL_FORCED_OUTAGE_PATROL: 'Patrol',
};
