/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import {
  ATMButton,
  ATMDatePicker,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMInput,
  ATMSelect,
  IORGDataTableQueryState,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import style from './forced-outage-list-search.module.scss';
import {
  ForcedOutageListSearchSchema,
  IForcedOutageListSearch,
} from 'src/models/forced-outage.model';
import Moment from 'src/libraries/moment.library';
import Lang from 'src/libraries/language';
import { useForcedOutageContext } from 'src/contexts/forced-outage.context';
import { FacilityType } from 'src/constants';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { getForcedOutageStatus } from 'src/selectors/forced-outage.selector';
import { forcedOutageListActionTypes } from 'src/ducks/forced-outage.duck';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import { substationActionTypes } from 'src/ducks/substation.duck';

type IProps = {
  loading?: boolean;
  filterValues?: React.MutableRefObject<Record<any, any>>;
  formRef?: React.RefObject<HTMLFormElement>;
  data?: IForcedOutageListSearch;
  handleSubmit: (data: Partial<IORGDataTableQueryState>) => void;
  handleClick?: () => void;
};

type IFormContent = {
  filterValues?: React.MutableRefObject<Record<any, any>>;
  loading?: boolean;
  handleClick?: () => void;
};

const FormContent: React.FC<IFormContent> = ({ handleClick }) => {
  const {
    control,
    formState,
    formState: { errors },
    reset,
    clearErrors,
    resetField,
  } = useATMFormContext<IForcedOutageListSearch>();
  const [facilityTypeId, setFacilityTypeId] = useState<number>();
  const { actions, state } = useForcedOutageContext();
  const { state: realtimeLogState, actions: realtimeLogActions } =
    useRealTimeLogContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();

  const status = getForcedOutageStatus(
    state,
    forcedOutageListActionTypes.FORCED_OUTAGE_LIST_DATA_READ
  );

  const lineStatus = getRealTimeLogStatus(
    realtimeLogState,
    realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ
  );

  const substationStatus = getSubstationStatus(
    substationState,
    substationActionTypes.SUBSTATION_LIST_READ
  );

  useEffect(() => {
    actions?.clearList();
    if (!realtimeLogState?.line?.length) {
      realtimeLogActions?.lineGET();
    }
    if (!substationState?.list.length) {
      substationActions?.listGET({
        limit: 0,
        page: 1,
      });
    }
  }, [actions, realtimeLogActions, substationActions]);

  return (
    <ATMGrid className={style.marginBotom}>
      <ATMGrid.Row className={style.rowBottom}>
        <ATMGrid.Column width={3}>
          <ATMField
            as={ATMInput}
            name="outageId"
            label={Lang.LBL_FORCED_OUTAGE_ID}
            placeholder="Enter"
            control={control}
            error={errors.outageId}
            onChange={([_, { value }]) => {
              if (!value) {
                setImmediate(() => {
                  resetField('outageId', {
                    defaultValue: '',
                  });
                });
              }
              return value;
            }}
            clearable
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={3} style={{ paddingLeft: '0' }}>
          <ATMField
            as={ATMInput}
            name="facilityName"
            label={Lang.LBL_FORCED_OUTAGE_FACILITY_NAME}
            placeholder="Enter"
            control={control}
            error={errors.facilityName}
            onChange={([_, { value }]) => {
              if (!value) {
                setImmediate(() => {
                  resetField('facilityName', {
                    defaultValue: '',
                  });
                });
              }
              return value;
            }}
            clearable
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={3} style={{ paddingLeft: '0' }}>
          <ATMField
            as={ATMSelect}
            name="facilityType"
            value={facilityTypeId}
            label={Lang.LBL_FORCED_OUTAGE_FACILITY_TYPE}
            placeholder={Lang.LBL_SELECT}
            options={Object.values(FacilityType)
              .filter((val) => typeof val === 'number')
              .map((value, key) => ({
                key,
                value,
                text: Lang.FACILITY_TYPE[value],
              }))}
            onChange={
              ((_, { value }) => {
                setFacilityTypeId(value);
                if (!value) {
                  setImmediate(() => {
                    resetField('substationId', {
                      defaultValue: '',
                    });
                    resetField('outgFacId', {
                      defaultValue: '',
                    });
                  });
                }
                return value;
              }) as any
            }
            clearable
            search
            selectOnBlur={false}
          />
        </ATMGrid.Column>
        <ATMGrid.Column
          width={3}
          className={style.selectables}
          verticalAlign="bottom"
        >
          {facilityTypeId === FacilityType.Line ? (
            <ATMField
              name="outgFacId"
              label={Lang.LBL_LINE}
              placeholder={Lang.LBL_SELECT}
              as={ATMSelect}
              control={control}
              loading={lineStatus.fetching}
              options={realtimeLogState.line.map((value) => ({
                key: value.outgFacId,
                value: value.outgFacId,
                text: value.outgFacNm,
              }))}
              onChange={([_, { value }]) => value}
              clearable
              search
              selectOnBlur={false}
            />
          ) : (
            <ATMField
              name="substationId"
              label={Lang.LBL_SUBSTATION}
              placeholder={Lang.LBL_SELECT}
              as={ATMSelect}
              control={control}
              loading={substationStatus.fetching}
              options={substationState.list.map((value) => ({
                key: value.substationId,
                value: value.substationId,
                text: value.name,
              }))}
              onChange={([_, { value }]) => value}
              clearable
              search
              selectOnBlur={false}
            />
          )}
        </ATMGrid.Column>
        <ATMGrid.Column width={3}>
          <div className={style.datepicker}>
            <ATMField
              as={ATMDatePicker}
              label={Lang.LBL_DATE_RANGE}
              size="small"
              type="range"
              format="MM/DD/YYYY"
              placeholder="Select"
              control={control}
              name="dateRange"
              onChange={([_, { value }]) => {
                if (value && value.length === 2) {
                  const [start, end] = value;
                  return [
                    Moment(start).startOf('day').toDate(),
                    Moment(end).endOf('day').toDate(),
                  ];
                }
                return value;
              }}
              clearable
            />
          </div>
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row className={style.buttonsTop}>
        <ATMGrid.Column>
          <ATMButton
            primary
            loading={status.fetching}
            content="Search"
            onClick={handleClick}
            disabled={
              !state.list?.length && (!formState.isDirty || status.fetching)
            }
          />
          <ATMButton
            secondary
            content="Clear Fields"
            type="button"
            disabled={
              !state.list?.length && (!formState.isDirty || status.fetching)
            }
            style={{ marginLeft: '1em' }}
            onClick={() => {
              reset({
                facilityType: '',
                dateRange: [],
                facilityName: '',
                outageId: '',
                outgFacId: '',
                substationId: '',
              });
              setFacilityTypeId('' as any);
              actions.clearList();
              setImmediate(() => {
                setImmediate(() => {
                  resetField('dateRange', {
                    defaultValue: [],
                  });
                });
                clearErrors();
              });
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const ForcedOutageListSearch: React.FC<IProps> = ({
  formRef,
  filterValues,
  handleSubmit,
  handleClick,
}) => {
  return (
    <div className={style.filterContainer}>
      <ATMForm
        ref={formRef}
        onSubmit={handleSubmit}
        defaultValues={{
          dateRange: [],
          facilityName: '',
          facilityType: '',
          outageId: '',
          outgFacId: '',
          status: undefined,
          substationId: '',
        }}
        mode="onChange"
        validationSchema={ForcedOutageListSearchSchema}
      >
        {(props) => {
          return (
            <ATMFormProvider {...props}>
              <FormContent
                filterValues={filterValues}
                handleClick={handleClick}
              />
            </ATMFormProvider>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default ForcedOutageListSearch;
