import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  ForcedOutageListPayloadSchema,
  ForcedOutagePayloadSchema,
  // ForcedOutageSchema,
  IOutageSharedInfoForm,
  OutageSharedInfoCreateSchema,
  OutageSharedInfoPayloadSchema,
} from 'src/models/forced-outage.model';

const client = httpClient();
const endpoint = 'forced-outage';

const services = {
  createPOST: async (data: IOutageSharedInfoForm) => {
    return client.post(`/${endpoint}`, data, OutageSharedInfoCreateSchema);
  },

  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, ForcedOutagePayloadSchema);
  },

  updatePUT: async (id: number, data: IOutageSharedInfoForm) => {
    return client.put(
      `/${endpoint}/${id}`,
      data,
      OutageSharedInfoPayloadSchema
    );
  },
  listGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    return client.get(`/${endpoint}`, data, ForcedOutageListPayloadSchema);
  },
};

export default services;
