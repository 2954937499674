import React, { useState } from 'react';
import {
  useATMFormContext,
  ATMIcon,
  ATMHeader,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMDatePicker,
  ATMDropdown,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IForcedOutageForm } from 'src/models/forced-outage.model';
import { LERRequestWidth } from 'src/constants';
import ForcedOutagePanel from '../foced-outage-panel/forced-outage-panel.component';
import style from './forced-outage-restoration-information.module.scss';
import { format24hTime } from 'src/libraries/moment.library';
import ForcedOutageRestorationSub from './forced-outage-restoration-sub/forced-outage-restoration-sub.component';

const FormContent: React.FC = () => {
  const { control, getValues } = useATMFormContext<IForcedOutageForm>();
  // const subFault = getValues('subFaultRelay');
  const [loadRestoreDt, setLoadRestoreDt] = useState<Date | undefined | null>(
    getValues('restorationInformation.loadRestoreDt')
  );
  const [loadDroppped, setLoadDroppped] = useState<boolean | undefined | null>(
    getValues('restorationInformation.loadDroppped')
  );

  const [loadDropDuration, setLoadDropDuration] = useState<
    number | undefined | null
  >(getValues('restorationInformation.loadDropDuration'));

  const [initiatingRowItem, setInitiatingRowItem] = useState<
    boolean | undefined | null
  >(getValues('restorationInformation.initiatingRowItem'));
  return (
    <>
      {/* <div className={style.formContent}></div> */}
      <span className={style.iconRight}>
        <ATMIcon circular name="user" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_FORCED_OUTAGE_RESTORATION}
        className={style.header}
      />
      <ATMGrid columns={2} style={{ maxWidth: 1023 }}>
        {/* </ATMGrid.Row><ATMGrid.Row> */}
        <ATMGrid.Column>
          <ATMField
            name="restorationInformation.loadRestoreDt"
            as={ATMDatePicker}
            is24hourformat
            label={Lang.LBL_FORCED_OUTAGE_LOAD_RESTORATION_DATE}
            control={control}
            value={format24hTime(loadRestoreDt)}
            defaulValue={format24hTime(loadRestoreDt)}
            onChange={([_, { value }]) => {
              setLoadRestoreDt(value);
              // if (value) {
              //   const [hour, minute] = value.split(':');
              //   return Moment().set({
              //     hour,
              //     minute,
              //   });
              // }

              return value;
            }}
            // onChange={(_, { value }) => {
            //   console.log('test loadRestoreDt', value);
            //   setLoadRestoreDt(value);
            // }}
          />
          {/* <Label attached="bottom">
                  <ATMHeader as="h2" content={Lang.LBL_FACILITY_INFORMATION} />
                </Label> */}
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="restorationInformation.loadDropDuration"
            as={ATMInput}
            label={Lang.LBL_FORCED_OUTAGE_LOAD_DROP_DURATION}
            control={control}
            value={loadDropDuration}
            defaultValue={loadDropDuration}
            onChange={(_, { value }) => {
              setLoadDropDuration(value);
            }}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="restorationInformation.loadDroppped"
            as={ATMDropdown}
            selection
            label={Lang.LBL_FORCED_OUTAGE_LOAD_DROPPED}
            control={control}
            value={loadDroppped}
            defaulValue={loadDroppped}
            clearable
            onChange={([_, { value }]) => {
              console.log('test value loadDroppped', value);
              setLoadDroppped(value);
              return value;
            }}
            options={[
              { key: 1, value: 1, text: 'True' },
              { key: 2, value: 2, text: 'False' },
            ]}
            // onChange={(_, { value }) => {
            //   console.log('test loadDroppped', value);
            //   setLoadDroppped(value);
            // }}
          />
        </ATMGrid.Column>

        <ATMGrid.Column>
          <ATMField
            name="restorationInformation.initiatingRowItem"
            as={ATMDropdown}
            selection
            control={control}
            label={Lang.LBL_FORCED_OUTAGE_INITIATING_ROW_ITEM}
            value={initiatingRowItem}
            defaultValue={initiatingRowItem}
            options={[
              { key: 1, value: 1, text: 'True' },
              { key: 2, value: 2, text: 'False' },
            ]}
            onChange={([_, { value }]) => {
              console.log('test value initiatingRowItem', value);
              setInitiatingRowItem(value);
              return value;
            }}
          />
        </ATMGrid.Column>
        {/* <ATMGrid.Column>
          <ATMField
            name="restorationInformation.piNotification"
            as={ATMInput}
            label={Lang.LBL_PI_NOTIFICATION}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="restorationInformation.wireDown"
            as={ATMInput}
            label={Lang.LBL_WIRE_DOWN}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="restorationInformation.ohug"
            as={ATMInput}
            label={Lang.LBL_OH_UG}
          />
        </ATMGrid.Column> */}

        <ATMGrid.Column width={16}>
          <ForcedOutageRestorationSub data={getValues()} />
        </ATMGrid.Column>
      </ATMGrid>
    </>
  );
};

const ForcedOutageRestorationForm: React.FC = () => {
  const { getValues } = useATMFormContext<IForcedOutageForm>();
  return (
    <ATMGrid divided>
      <ATMGrid.Column width={LERRequestWidth.LEFT}>
        <FormContent />
      </ATMGrid.Column>
      <ATMGrid.Column width={LERRequestWidth.RIGHT}>
        <ForcedOutagePanel data={getValues()} />
        {/* <LERRequestPanel data={getValues()} /> */}
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default ForcedOutageRestorationForm;
