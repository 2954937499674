import {
  forcedOutageListActionTypes,
  IForcedOutageState,
} from 'src/ducks/forced-outage.duck';
import { IStatus } from 'src/libraries/thunk.library';

export const getForcedOutageStatus = (
  state: Partial<IForcedOutageState>,
  action: keyof typeof forcedOutageListActionTypes
): IStatus =>
  (state.status && state.status[action]) || {
    fetching: false,
    error: null,
  };
