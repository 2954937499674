import React, { useState } from 'react';
import {
  ATMButton,
  ATMHeader,
  ATMIcon,
  ATMModal,
  ATMTable,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IForcedOutageForm } from 'src/models/forced-outage.model';
import style from './forced-outage-patrol.module.scss';

type IModalProps = {
  isOpen: boolean;
  setIsOpen: any;
};

const PatrolModal: React.FC<IModalProps> = ({ isOpen, setIsOpen }) => {
  return (
    <ATMModal
      open={isOpen}
      size="large"
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      trigger={
        <ATMButton content="Add Row" secondary size="small" type="button" />
      }
    >
      <ATMModal.Header as="h2">Test</ATMModal.Header>
      <ATMModal.Content
        style={{ marginBottom: '100px', height: '70vh', overflowY: 'auto' }}
      >
        Test is Test
      </ATMModal.Content>
    </ATMModal>
  );
};

type IProps = {
  data: Partial<IForcedOutageForm>;
};

const ForcedOutagePatrol: React.FC<IProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* Table */}
      <span className={style.iconRight}>
        <ATMIcon circular name="calendar" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.LBL_FORCED_OUTAGE_PATROL_INFORMATION}
        className={style.header}
      />

      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell colSpan={2}>
              {Lang.LBL_FORCED_OUTAGE_PATROL_DATE}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>
              {Lang.LBL_FORCED_OUTAGE_COMMENT}
            </ATMTable.HeaderCell>
            {/* <ATMTable.HeaderCell>{Lang.LBL_RELAY_MI}</ATMTable.HeaderCell> */}
          </ATMTable.Row>
        </ATMTable.Header>

        <ATMTable.Body>
          {data.outageInformation?.patrol &&
          data.outageInformation?.patrol.length ? (
            <ATMTable.Row>
              <ATMTable.Cell colSpan={4} textAlign="center">
                {Lang.NTE_NO_DATA}
              </ATMTable.Cell>
            </ATMTable.Row>
          ) : (
            <ATMTable.Row>
              <ATMTable.Cell colSpan={4} textAlign="center">
                {Lang.NTE_NO_DATA}
              </ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
      {/* Button */}
      <div style={{ float: 'right' }}>
        <PatrolModal isOpen={isOpen} setIsOpen={setIsOpen} />
        {/* <ATMButton type="button" onClick={handleOpen}>
          Add Row
        </ATMButton> */}
      </div>
    </>
  );
};

export default ForcedOutagePatrol;
