import React, { useCallback, useState } from 'react';
import { ATMHeader, ATMLabel, MOLRightPanel } from 'shared-it-appmod-ui';
import { AccessType } from 'src/constants';
import { ILerRequestDraft } from 'src/models/ler-request.model';
import Access from 'src/components/atoms/access/access.component';
import styles from './forced-outage-add.module.scss';
import Lang from 'src/libraries/language';
import ForcedOutageForm from '../forced-outage-form/forced-outage-form.component';

type IContentProps = {
  data?: Partial<ILerRequestDraft>;
  setClose?: () => void;
};

const Content: React.FC<IContentProps> = ({ setClose }) => {
  return (
    <ForcedOutageForm
      header={
        <ul>
          <li>
            <ATMHeader>{Lang.TTL_NEW_FORCED_OUTAGE}</ATMHeader>
          </li>
          <li>
            <ATMLabel className={styles.labelDraft}>{Lang.TTL_DRAFT}</ATMLabel>
          </li>
        </ul>
      }
      defaultValues={undefined}
      handleClose={() => {
        setClose && setClose();
      }}
      // handleSave={}
      handleSubmit={() => setClose && setClose()}
      loading={false}
      // draftId={}
    />
  );
};

type IProps = {
  data?: Partial<ILerRequestDraft>;
  open?: boolean;
  trigger?: React.ReactNode;
  onClose?: () => void;
};

const ForcedOutageRequestAdd: React.FC<IProps> = ({
  data,
  open,
  trigger,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose, setIsOpen]);

  return (
    <Access type={AccessType.FORCED_OUTAGE}>
      <MOLRightPanel
        id="forced-outage-form"
        isOpen={open || isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={handleClose}
        trigger={trigger}
        className={styles.floatingPanel}
        width="calc(100vw - 200px)"
        closeOnDimmerClick={false}
      >
        {(open || isOpen) && <Content data={data} setClose={handleClose} />}
      </MOLRightPanel>
    </Access>
  );
};

export default ForcedOutageRequestAdd;
