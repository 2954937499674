import React, { useState } from 'react';
import {
  IForcedOutage,
  IForcedOutageForm,
} from 'src/models/forced-outage.model';
import styles from './forced-outage-overview.module.scss';
import {
  ATMAccordion,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  MOLReadonlyField,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import moment from 'moment';

type IProps = {
  data?: Partial<IForcedOutageForm>;
  details?: IForcedOutage;
  handleEdit?: () => void;
};

const ForcedOutagePanelOverview: React.FC<IProps> = ({
  data,
  // details,
  handleEdit,
}) => {
  const [active, setActive] = useState(true);
  if (!data) {
    return null;
  }
  const causeCdOptions = [
    {
      key: 1010,
      value: 1010,
      text: '1010 - Flashover Insulator (non-wash)',
    },
    {
      key: 1011,
      value: 1011,
      text: '1011 - Flashover Insulator, to be patrolled',
    },
  ];

  const durationComputation = (
    startDate: Date,
    startTm: string,
    endDate: Date,
    endTm: string
  ) => {
    const [startMinute, startSecond] = startTm.split(':');
    const [endMinute, endSecond] = endTm.split(':');

    const start = moment(startDate)
      .add('hours', startMinute)
      .add('minutes', startSecond) as any;
    const end = moment(endDate)
      .add('hours', endMinute)
      .add('minutes', endSecond) as any;

    const computedMinutes = end.diff(start, 'minutes');

    if (computedMinutes < 1440) {
      return (computedMinutes / 60).toFixed(2) + ' hr/s';
    } else {
      return (computedMinutes / 1440).toFixed(2) + ' day/s';
    }
  };

  const getStartEndDtTm = (date: Date, time: string) => {
    let day = `${date?.getDate()}`;
    let month = `${date?.getMonth()}`;
    const year = date?.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }

    return `${month}/${day}/${year} ${time}`;
  };

  const overviewDetails = (
    <>
      <span className={styles.iconRight}>
        <ATMIcon circular name="building outline" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_OUTAGE_OVERVIEW}
        className={styles.header}
      />
      <ATMGrid columns={2}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_START_DATE}
              value={
                data.startDate && data.startTm
                  ? getStartEndDtTm(data.startDate, data.startTm)
                  : '-'
              }
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_END_DATE}
              value={
                data.endDate && data.endTm
                  ? getStartEndDtTm(data.endDate, data.endTm)
                  : '-'
              }
              // value={formatDateTime(data.endDate) ?? '-'}
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_DURATION}
              value={
                data.startDate && data.startTm && data.endDate && data.endTm
                  ? durationComputation(
                      data.startDate,
                      data.startTm,
                      data.endDate,
                      data.endTm
                    )
                  : '-'
              }
              inline={false}
            />
          </ATMGrid.Column>

          <ATMGrid.Column width={16}>
            <MOLReadonlyField
              label={Lang.LBL_DESCRIPTION}
              value={data.description ?? '-'}
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column width={16}>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_CAUSE_CODE}
              value={
                causeCdOptions.find((cd) => cd.value === data.causeCd)?.text ??
                '-'
              }
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column width={16}>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_SUPPLEMENTAL_CC}
              value={
                causeCdOptions.find((ss) => ss.value === data.suppCauseCd)
                  ?.text ?? '-'
              }
              inline={false}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );
  return data ? (
    overviewDetails
  ) : (
    <ATMAccordion style={{ marginBottom: '1rem' }} fluid>
      {/* View of FO Overview */}
      <ATMAccordion.Title
        active={active}
        index={0}
        onClick={() => setActive((val) => !val)}
      >
        <ATMIcon name="angle down" />
        {Lang.TTL_OUTAGE_OVERVIEW}

        {handleEdit && (
          <a className={styles.edit} onClick={handleEdit} role="button">
            <ATMIcon name="edit" />
            {Lang.LBL_EDIT}
          </a>
        )}
      </ATMAccordion.Title>
    </ATMAccordion>
  );
};

export default ForcedOutagePanelOverview;
